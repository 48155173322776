.icon{

	&--round-background{
		background-color: $grey-1;
		@include set-topic-color(background-color);
		height: 130px;
		width: 130px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	
	i{
		color: $base-font-color;
		&::before{
			@include font-size(5);
		}
	}
	
	svg{
		//max-width: 45%;
		path{
			fill: $base-font-color;
		}
	}
}

@include media-breakpoint-down(md) {
	.icon{
		&--round-background{
			width: 100px;
			height: 100px;
			i{
				&::before{
					@include font-size(3);
				}
			}
		}
	}
}