.subnavi-navi-wrapper {
	@include make-row();
	margin-bottom: $base-margin*3;
	
	button{
		text-transform: uppercase;
		font-family:$base-font-family-light;
		cursor: pointer;
		margin-left:$base-margin;
		margin-right:$base-margin;
		border: 1px solid $base-font-color;
		padding: $base-padding/2;
		
		
		i{
			margin-right:$base-margin/4;
		}
	
	
	}
	
	&__inner {
		@include setBasicModuleWidth-MediumLarge();
		
		
		.glossar-navi{
			@include reset-list();
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			justify-content: center;
			margin-left: auto;
			margin-right: auto;
			
			
			@include media-breakpoint-down(sm) {
				padding: $base-padding/2 $base-padding;	
			}
	
			&__item{
				display: flex;
				justify-content: center;
				align-items: center;
				@include font-size(2.1);
				font-family: $base-font-family-light;
				margin-right:$base-margin;
				margin-bottom: $base-margin/2;
				
				@include media-breakpoint-down(sm) {
				//	display: block;
				//	width: 100%;
				}
				
				a{
					color: $base-font-color;
					transition: all $base-duration $base-timing;
					position: relative;
					display: flex;
					background-color: $white;
					color: $primary-color-1;
					width: 35px;
					height: 35px;
					justify-content: center;
					align-items: center;
					border: 1px solid $primary-color-1;
					
					&:hover{
						background-color: $primary-color-1;
						color: $white;

						&::after{
							width: 100%;
						}
					}
				}
				&--active{
					a{	
						background-color: $primary-color-1;
						color: $white;
					}
				}
			}
		}
		
		.subnavi-navi{
			@include reset-list();
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			justify-content: center;
			
			
			@include media-breakpoint-down(sm) {
				padding: $base-padding/2 $base-padding;	
			}
	
			&__item{
				display: flex;
				justify-content: center;
				align-items: center;
				@include font-size(1.9);
				font-family: $base-font-family-light;
				margin-right:$base-margin*1.5;
				margin-bottom: $base-margin/2;
				
				@include media-breakpoint-down(sm) {
					display: block;
					width: 100%;
				}
				
				a{
					color: $base-font-color;
					transition: all $base-duration $base-timing;
					position: relative;
					
					&::before{
						height: 3px;
						width: 100%;
						background-color:$grey-2;
						content: ''; 
						position: absolute;
						left:0;
						bottom: -4px;
						transition: all $base-duration $base-timing;
					}
					&::after{
						height: 3px;
						width: 0%;
						background-color:$primary-color-4;
						content: ''; 
						transition: all $base-duration*1.5 $base-timing;
						position: absolute;
						left:0;
						bottom: -4px;
					}
					
					&:hover{


						&::after{
							width: 100%;
						}
					}
				}
				&--active{
					a{
						&::after{
							width: 100%;
						}
					}
				}
			}
		}
	}
	
	body.topic--primary-color-3 & {
		
		&__inner {
		
		
			.subnavi-navi{
					
				&__item{
					
					a{
						&::after{
							background-color: $primary-color-3;
						}
					}
				}
			}
		}
	}
	
}