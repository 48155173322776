.article-list {
	@include reset-list();
	@include make-row();
	
	
	&--1er-teaser {
		&__item {
			@include setBasicModuleWidth-Full();
			@include make-col(12);
			height: 100%;
		}
	}
	
	
	&--2er-teaser {
		&__item {
			@include setBasicModuleWidth-Full();
			@include make-col(6);
			height: 100%;

			@include media-breakpoint-down(xs) {
				@include make-col(12);
				/*
				.article-teaser-wrapper {
					max-width: 250px;
					min-width: 200px;
				}
				*/	
			}
		}
	}

	&--3er-teaser {
		&__item {
			@include setBasicModuleWidth-Full();
			@include make-col(4);
			height: 100%;

			@include media-breakpoint-down(xs) {
				@include make-col(12);
				/*
				.article-teaser-wrapper {
					max-width: 250px;
					min-width: 200px;
				}
				*/
			}
		}
	}


	&--4er-teaser {
		&__item {
			@include setBasicModuleWidth-Full();
			@include make-col(3);
			margin-bottom: $base-margin;

			@include media-breakpoint-down(md) {
				@include make-col(4);
			}
			@include media-breakpoint-down(sm) {
				@include make-col(6);
			}
			@include media-breakpoint-down(xs) {
				@include make-col(12);
				/*
				.article-teaser-wrapper {
					max-width: 250px;
					min-width: 200px;
				}
				*/
			}
		}
	}
	
	&--66-33er-teaser {
		&__item {
			@include setBasicModuleWidth-Full();
			@include make-col(12);
			height: 100%;
		}

		// hide image on tablet				
		.article-list__item--33 .article-teaser-veranstaltung-wrapper__content__link__inner__image,
		.article-list__item--66 .article-teaser-veranstaltung-wrapper__content__link__inner__image,
		.article-list__item--66 .article-teaser-standard-wrapper__content__link__inner__image{
			@include media-breakpoint-down(lg) {
				//display: none;
			}
			@include media-breakpoint-down(md) {
				display: none;
			}
			@include media-breakpoint-down(sm) {
				display: none;
			}
		}	
	}
	

	&--slider {
		
		display: flex;
		//flex-direction: column-reverse;
		
		&-setup {
			.owl-stage-outer {
			//	padding: 15px 0;
			}

			.owl-stage {
				display: flex;
				.article-list--1er-teaser__item,
				.article-list--2er-teaser__item,
				.article-list--3er-teaser__item,
				.article-list--4er-teaser__item {
					@include make-col(12);
				}
				.article-list--66-33er-teaser__item-33,
				.article-list--66-33er-teaser__item-66{
					//@include make-col(12);
				}
			}
		}
	}
	
	&--slider-list{
		@include sliderNavi($slider-single: false);
	}
	&--slider-single{
		@include sliderNavi($slider-single: true);
	}
	
}
