.figure {

	margin: 0;
	display: flex;
	flex-direction: column;
	
	img{
		text-align: center;
		@include font-size($caption-font-size);
		color: $grey-1;
	}

	> div {
		background-color: $grey-3;
	}
	
	a{
		position: relative;
		display: block;
		background-color: $grey-3;
		
		&:hover{
			.corner-icon{
				&--top-right{
					height: 30px;
					width: 30px;
				}
			}
		}
		
	}
	.corner-icon{
		background: transparent url('../Images/Icons/ic_expend.svg') center center no-repeat;
		background-size: cover;
		position: absolute;
		height: 25px;
		width: 25px;
		left: $base-margin;
		top:$base-margin;
		transition: all $base-duration $base-timing;
		
		&--top-right{
			right: $base-margin;
			left: auto;
		}
		
	}
	

	

	&__image {

		&--responsive {
			width: 100%;
			//height: auto;
			height: 100%;
		}

		&--lazy {
			background-image: url('../Images/Spinner.svg');
		    background-repeat: no-repeat;
		    background-position: 50% 50%;
		}

		&--blur {
			filter: blur(3px);
		}
		
		&--hide-mobile{
			@include media-breakpoint-down(sm) {
				display: none;
			}
		}
		&--show-mobile{
			@include media-breakpoint-up(md) {
				display: none;
			}
		}
		
		
	}

	&__caption {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		@include font-size($caption-font-size);
		color: $grey-1;
		

		&__copyright {
			
			margin: $base-margin/2 0;
			
			i{
				font-weight: 300;
			}
			
			&--hide {
				display: none;
			}

		}
		&__description {
			color: $base-font-color;
			&--hide {
				display: none;
			}
		}

		&--no-caption {
			display: none;
		}
		
		&--teaser{
			//flex-direction: row-reverse;
			flex-direction: column-reverse;
			padding: $base-padding/2 $base-padding;
			position: relative;
			//align-items: center;
			//flex-wrap: wrap;
			background-color: $white;

			
			&::after{
				content: '';
				width:35px;
				height: 1px;
				background-color: $base-font-color;
				position: absolute;
				left:0;
				bottom: 0;
			}
			
			&__copyright{
				margin: 0;				
				@include font-size(1);

			}
			&__description{
				width: auto;
			}
			
		}
		
	}
	
	&--round{
		&>div,
		img{
			border-radius: 50%;
		}
	}
	
	
	
	
}
// Setup for aspect ratio placeholder
// https://css-tricks.com/aspect-ratio-boxes/
// adapted for our use

/*

[style*="--aspect-ratio"] > :first-child {
  width: 100%;
}
[style*="--aspect-ratio"] > img {
  height: auto;
}

@supports (--custom: property) {
  [style*="--aspect-ratio"] {
    position: relative;
  }
  [style*="--aspect-ratio"]::before {
    content: "";
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
  }
  [style*="--aspect-ratio"] > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}

*/


// Format 9 zu 16 funkioniert nicht!!!

.image-placeholder {
	& > :first-child {
		width: 100%;
	}
	& > img {
		height: auto;
	}

	&--2zu1,
	&--2zu3,
	&--1zu1,
	&--16zu9,
	&--3zu1,
	&--4zu3,
	&--3zu4 {
		position: relative;
		& > :first-child {
		    position: absolute;
		    top: 0;
		    left: 0;
		    height: 100%;
		}
	}
	&--16zu9 {
		&::before {
			content: "";
			display: block;
			padding-bottom: calc(100% / (16/9));
  		}
	}
	
	&--3zu1 {
		&::before {
			content: "";
			display: block;
			padding-bottom: calc(100% / (3/1));
  		}
	}

	&--4zu3 {
		&::before {
			content: "";
			display: block;
			padding-bottom: calc(100% / (4/3));
  		}
	}
	&--3zu4 {
		&::before {
			content: "";
			display: block;
			padding-bottom: calc(100% / (3/4));
  		}
	}
	&--2zu3 {
		&::before {
			content: "";
			display: block;
			padding-bottom: calc(100% / (2/3));
  		}
	}
	&--2zu1 {
		&::before {
			content: "";
			display: block;
			padding-bottom: calc(100% / (2/1));
  		}
	}
	&--1zu1 {
		&::before {
			content: "";
			display: block;
			padding-bottom: calc(100% / (1/1));
  		}
	}
}


