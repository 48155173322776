
.landingpage{

	p{margin-bottom: $base-margin*2.5;}

}


#stoerer{
	display: flex;
	justify-content: center;
	align-items: center;
	width:215px;
	height:215px;
	text-align: center;
	color:#fff;
	background-color: $black;
	border-radius: 50%;
	position: fixed;
	bottom:$base-padding*4;
	left:$base-padding*4;
	left:5%;
	@include font-size(1.8);
	transform: rotate(-15deg);

	@include media-breakpoint-down(sm) {
		display:none;

		@include font-size(1.2);
			width:120px;
			height:120px;
	}

}

.module-landingpage{
	@include make-row();
	padding-top: $base-padding*8;
	padding-bottom: $base-padding*8;

	@include media-breakpoint-down(md) {
		padding-top: $base-padding*4;
		padding-bottom: $base-padding*4;
	}

}

.module-wrapper--intro{
	background-image: url('../Images/Landingpage/TM_Deutsches-Theater_33_GRUPPE_145.jpg');
	background-size: cover;
	background-position: center top;
}

.module-intro{

	height:  calc(100vh - 110px);
	position: relative;




	&__inner{
		@include make-col-ready();
		@include make-col(12);

		padding-top: $base-padding*2;
		display: flex;
		align-items: center top;
		height: 100%;

		h1{
			@include font-size(7.2,7.5);
			font-family: $base-font-family;
			color:#ffffff;
			margin:0;
			span{
				font-family: $base-font-family-light;
				display: block;
			}
			//margin-top:$base-margin;

			@include media-breakpoint-down(lg) {
				@include font-size(6,6);
			}

			@include media-breakpoint-down(sm) {
				@include font-size(5,5);
			}

		}



	}
	#scroll-icon{
		position: absolute;
		bottom: $base-margin*2;
		right:0;
		transform: rotate(-90deg);
		@include font-size(1.5);
		a{
			display:flex;
			align-items: center;
			span{
				transform: rotate(90deg);
				@include font-size(2.5);
				margin-right: $base-margin/2;
				font-family: $base-font-family-light;
			}
		}
		i{
			@include font-size(1.5);
			//transform: rotate(90deg);
			&::before{
				font-weight: 200;
			}
		}

		i.fa-pipe{
			transform: rotate(90deg);
			margin-left:$base-margin/2;
		}
		i.fa-circle{
			@include font-size(0.7);
			margin-left:$base-margin/2;
		}
		@include media-breakpoint-down(sm) {
			display:none;
		}
	}
}

.module-welcome{


	&__inner{
		@include make-col-ready();
		@include make-col(8);
		@include make-col-offset(4);

		@include font-size(3.6,4.4);
		font-family: $base-font-family;


		@include media-breakpoint-down(md) {
			@include make-col(12);
			@include make-col-offset(0);
		}


	}
}


.module-intendanz{
	&__inner{
		@include make-col-ready();
		@include make-col(12);

		.module-double-text{
			@include make-row();


			&__left{
				@include make-col-ready();
				@include make-col(3.5);
				@include make-col-offset(4);
				@include font-size(3.6);


				@include media-breakpoint-down(lg) {
					@include make-col(12);
					@include make-col-offset(0);
				}



				p{
					margin-bottom: $base-margin/2;
				}

				p.name{
					border-bottom: 1px solid $grey-1;
					display: inline-block;
					margin-top: $base-margin;
				}

				p.position{
					@include font-size(1.8);
				}

			}
			&__right{
				@include make-col-ready();
				@include make-col(4.5);
				@include media-breakpoint-down(md) {
					@include make-col(8);
				}

				@include media-breakpoint-down(sm) {
					@include make-col(12);
				}
			}
		}

		.ensemble-list{
			@include make-row();

			.article-list__item{
				@include make-col-ready();
				@include make-col(3);

				@include media-breakpoint-down(lg) {
					@include make-col(4);
					}

				@include media-breakpoint-down(md) {
					@include make-col(6);
					}
				@include media-breakpoint-down(sm) {
					@include make-col(12);
				}

			}
		}


		.module-ensemble{

			p.headline{
				margin-bottom: $base-margin*4;
				@include font-size(3.6);
				text-transform: uppercase;
				font-family: $base-font-family;
			}

			p.name{
				@include font-size(2.4);
			}

			margin-top: $base-margin*2;

			.ensemble-member{

				p{
					border-bottom: 1px solid $grey-1;
					display: inline-block;
					@include font-size(2.4);
					margin-top: $base-margin/2;
				}

			}

			.owl-nav{
				position: absolute;
				left: 0;
				top:-40px;
				position: flex;
				justify-content: flex-start;


			}
		}

	}
}

.module-premieren{


	&__inner{
		@include make-col-ready();
		@include make-col(12);


		h2{
			@include font-size(7.2,7.5);
			font-family: $base-font-family;
			margin: 0;
			margin-top: $base-margin*3;
			margin-bottom: $base-margin*6;
			span{
				font-family: $base-font-family-light;
			}

			@include media-breakpoint-down(lg) {
				@include font-size(6,6);
			}

			@include media-breakpoint-down(sm) {
				@include font-size(5,5);
			}

		}

		.premieren-list{
			@include reset-list();
			@include make-row();

			&__item{
				@include make-col-ready();
				@include make-col(4);
				margin-bottom: $base-padding;


				@include media-breakpoint-down(xl) {
					@include make-col(6);
					//@include make-col-offset(0);
				}


				@include media-breakpoint-down(md) {
					@include make-col(12);
					//@include make-col-offset(0);
				}



				&__wrapper{
					background-color: $primary-color-2;
					padding: $base-padding*2;
					min-height: 435px;

					h3{
						@include font-size(3.6,3.6);
						font-family: $base-font-family;
						text-transform: uppercase;
					}

					p{
						margin-bottom: $base-margin;
						@include font-size(1.6);
					}

					p.date{
						@include font-size(3.6,3.6);
						font-family: $base-font-family-light;
						margin-bottom: 0;
					}

					p.place{
						text-transform: uppercase;
						font-family: $base-font-family;
					}

				}
			}

			&__lastitem{
				@include make-col-ready();
				@include make-col(4);
				margin-bottom: $base-padding;
				&__wrapper{
					background-color: $primary-color-2;
					height: 405px;
					border-radius: 0 50rem 50rem  0;
				}

				@include media-breakpoint-down(xl) {
					@include make-col(6);
					//@include make-col-offset(0);
				}
				@include media-breakpoint-down(md) {
					@include make-col(12);
					//@include make-col-offset(0);
				}

			}
		}

	}
}


.module-profil{


	&__inner{
		@include make-col-ready();
		@include make-col(12);

		.module-double-text{
			@include make-row();


			&__left{
				@include make-col-ready();
				@include make-col(4);
				@include font-size(1.8);
				text-transform: uppercase;
				font-family: $base-font-family;

				@include media-breakpoint-down(md) {
					@include make-col(12);
					//@include make-col-offset(0);
				}


			}
			&__right{
				@include make-col-ready();
				@include make-col(8);
				@include font-size(2.4);

				@include media-breakpoint-down(md) {
					@include make-col(12);
				//	@include make-col-offset(0);
				}

			}
		}

		h2{
			@include font-size(7.2,7.5);
			font-family: $base-font-family;
			margin: 0;
			margin-top: $base-margin*3;
			margin-bottom: $base-margin*6;

			@include media-breakpoint-down(lg) {
				@include font-size(6,6);
			}

			@include media-breakpoint-down(sm) {
				@include font-size(5,5);
				margin-top: $base-margin*1.5;
			margin-bottom: $base-margin*3;
			}

		}

	}
}


.module-spielzeitheft{

	height: 100vh;

	@include media-breakpoint-down(xl) {
		height: 100vh;
	}

	@include media-breakpoint-down(md) {
		height: 100vh;
	}

	&__inner{
		@include make-col-ready();
		@include make-col(12);
		color: $white;
		display: flex;
		flex-direction: column;
		justify-content: center;

		@include media-breakpoint-down(xl) {
			justify-content: flex-start;
		}

		@include media-breakpoint-down(md) {
		//	justify-content: flex-start	;
		}

		h4{
			@include font-size(3.6,4.2);
			font-family: $base-font-family-light;
			margin-bottom: $base-margin*2;
		}

		p{
			margin-bottom: $base-margin/2;
			font-family: $base-font-family;
		}

		a{
			color: $white;
			@include font-size(2.4);
			border-bottom: 1px solid $white;
			transition: all $base-duration $base-timing;
			&::after{
				@include font-size(2.4);
			}
			&:hover{
				border-bottom: 1px solid $primary-color-1;
			}
			span{
				@include font-size(3);
				font-family: $base-font-family-light;
			}
		}
	}
	background: url('../Images/Landingpage/DT_Spielzeitheft_Quadrat.png') right center no-repeat;
	background-size: 1000px 1000px;

	@include media-breakpoint-down(xxl) {
		background-size: 800px 800px;
		//background: none;
	}

	@include media-breakpoint-down(xl) {
		background: url('../Images/Landingpage/DT_Spielzeitheft_Quadrat.png') center bottom no-repeat;
		background-size: 800px 800px;
	}

	@include media-breakpoint-down(md) {
		background-size: 650px 650px;
	}

	@include media-breakpoint-down(sm) {
		background-size: 350px 350px;
	}
}


.module-kontakt{

	&__inner{

		@include make-col-ready();
		@include make-col(12);

		.module-double-text{
			@include make-row();

			&__left{
				@include make-col-ready();
				@include make-col(4);
				@include font-size(3.6);
				font-family: $base-font-family;


				@include media-breakpoint-down(md) {
					@include make-col(12);
				}

			}
			&__right{
				@include make-col-ready();
				@include make-col(8);
				@include font-size(3.6);
				font-family: $base-font-family-light;
				@include media-breakpoint-down(md) {
					@include make-col(12);
				}
			}
		}


		.module-double-text-2{
			@include make-row();
			margin-top: $base-margin*2;

			a{
					border-bottom: 1px solid $grey-1;
				}

			&__left{
				@include make-col-ready();
				@include make-col(3.5);
				@include font-size(1.8);
				@include make-col-offset(4);

				@include media-breakpoint-down(md) {
					@include make-col(12);
					@include make-col-offset(0);
				}
			}
			&__right{
				@include make-col-ready();
				@include make-col(3.5);
				@include make-col-offset(0.5);
				@include font-size(1.8);



				@include media-breakpoint-down(md) {
					@include make-col-offset(0);
					@include make-col(12);
				}

			}
		}
	}
}


.offcanvas{
	background-color: $primary-color-4;
	--bs-offcanvas-width: 800px;

	button{
		border: 0;
		padding: 0;
		background-color: $primary-color-4;
		margin-left: $base-margin*1.5;
		i{
			@include font-size(3);
			font-weight: 200;
		}
		&:hover{
			i::before{
				color:$primary-color-3;
			}
		}
	}

	.offcanvas-header{
		padding: 0 !important;
	}

	.offcanvas-body{
		padding: 0 !important;

		.offcanvas__inner{
			background-color: $white;
			margin-left: $base-margin*1.5;
			margin-right: $base-margin*1.5;
			margin-bottom: $base-margin*1.5;
		}


		.offcanvas-content{
			&__text{
				padding: $base-padding*2;

				h3{
					@include font-size(3.6,4);
					margin-bottom:$base-margin*2;
					span{
						font-family: $base-font-family-light;

					}
				}

				p{
					margin-bottom: $base-margin;
					&:last-child{
						margin-bottom: 0;
					}
				}
			}
		}

	}

}
