
.directory-list{

	@include reset-list();
	

	&__item{
		
		
		&__content-wrapper{
			display: flex;
			flex-direction: row;
			border-left:4px solid $primary-color-1;
			
			
			
			
			&__basic{
				
				border-bottom: 1px solid $grey-2;
				
		
				
				display: flex;
				flex: 1;
				
				p{
					margin-bottom: 0;
				}
				
				&__left{
					padding: $base-padding;
					width: 43%;
				}
				
				&__right{
					padding: $base-padding $base-padding/4 $base-padding/4 $base-padding;
 
					border-left: 1px solid $grey-2;
					width: 57%;
					display: flex;
					flex-direction: column;
					justify-content:space-between;
					//justify-content:flex-end;
					text-align: right;
					
					&__up{
						
						ul{
							@include reset-list();
							text-align: right;
							margin-bottom: $base-margin;
							//max-width: 93%;
							float: right;
							li{
								display: inline;
								white-space: nowrap;
								margin-left: $base-margin/2;
								@include font-size(1.5);
								i{
									color:$primary-color-1;
								}
							}
							
							li.active{
								i{
									color: $primary-color-3
								}
							}
							
						}
						
					}
					
					
				
					&__down{
						align-self: flex-end;
						a{
							margin-left: $base-margin/2;
						}
					}
				
				}
				
			@include media-breakpoint-down(lg) {
				&__right{
					&__up{		
						ul{
							max-width: 85%;
						}
					}
				}
			}
			@include media-breakpoint-down(md) {
				flex-direction: column;
				
				&__left,
				&__right{
					width: 100%;
				}
				
				&__right{
						border-top: 1px solid $grey-2;
					text-align: left;
					&__up{		
						ul{
							max-width: 100%;
							float: left;
							text-align: left;
							li{
								margin-left: 0;
								margin-right: $base-margin/2;
							}
						}
					}
					&__down{		
						align-self: flex-start;
						a{
							margin-left: 0;
							margin-right: $base-margin/2;
						}
					}
				}
			
			}
			}
		}
		
		
		.directory-item-title{
			@include font-size(2.1);
		}
		
		.directory-item-subtitle{
			@include font-size(1.7);
			margin-bottom: $base-margin/2;
		}
		
		.directory-item-place{
			@include font-size(1.5);
			color:$grey-1;
		}
		
	}	

}



.directory-page-browser {
	@include make-row();

	&__inner {
		@include setBasicModuleWidth-Full();

		.pagination-wrapper{
			
			border: 1px solid $grey-2;
			display: flex;
			padding: $base-padding;
			background-color: $grey-3;
			justify-content: center;
			align-content: center;
			
			&--white{
				border: 0;
				background-color: $white;
			}
			
			ul{
				@include reset-list();
				display: flex;
				justify-content: center;
				align-items:center;
				
				li{
					font-family: $heading-font-family-style-2;
					margin: 0 $base-margin/2;
					@include font-size(2.1);
					
					span.current{
						@include font-size(5.2,3.5);
						//margin-top: -8px;
					}
					
					i{
						font-weight: 600;
						color: $base-link-color;
						&::before{
						@include font-size(1.6);
						}
					}
					
					&.disabled{
						a{
							color: $grey-1;
							pointer-events: none;
						}
					}
					
				}
			}
			
		}

			
		@include media-breakpoint-down(lg) {
		
		}
		
		@include media-breakpoint-down(md) {
		
		}
		
		@include media-breakpoint-down(sm) {
		
		}	
	}
}


.module-article-directory {
	@include make-row();
	margin-bottom: $base-margin*3;

	&__inner {
		@include setBasicModuleWidth-Full();
		
		&__content{
			@include make-row();
			&__inner{
				@include setBasicModuleWidth-Medium();
			}
		}
		&__counter{
			@include make-row();
			&__inner{
				@include setBasicModuleWidth-Medium();
				text-align: center;
				margin-bottom: $base-margin;
			}
		}
		
	}
}



.directory-filter{
	@include make-row();
	margin-bottom: $base-margin;

	&__inner{
		
		@include setBasicModuleWidth-Medium();
		
		
		
		&__content{
			@include make-row();
			
			&__inner{
				@include setBasicModuleWidth-Full();
				
				padding-bottom: $base-padding;
				
				.filter-form{
					margin-top: $base-margin;
					padding-bottom: $base-padding;
					padding-top: $base-padding;
					padding: $base-padding;
					background-color: $grey-3;
					
					
					&__basics{
						border-bottom:2px solid $primary-color-1;
						padding-bottom:$base-margin;
						
						
						.row__item{
							@include make-col-ready();
							@include make-col(4);
							
								@include media-breakpoint-down(sm) {
									@include make-col(12);
									margin-bottom: $base-margin/2;
								}
							
						}
						
						nav{
							.dropdown{		
								button{
									width: 100%;
									text-align: left;
									justify-content: space-between;
								}		
							}
						}
					}
					
					&__letter-filter{
						
						border-bottom:1px solid $primary-color-1;
						
						.letter-filter{
								@include reset-list();
								display: flex;
								margin: $base-margin 0 0;
								flex-wrap: wrap;
								
								
								li{
									margin-right: $base-margin/1.5;
									margin-bottom: $base-margin/1.5;
									
									a{
										display: flex;
										background-color: $primary-color-1;
										align-items: center;
										justify-content: center;
										width: 30px;
										height: 30px;
										color: $white;
										transition: all $base-duration $base-timing;
										
										&.active-letter,
										&:active,
										&:hover{
											background-color: $white;
											color: $primary-color-1;
											border: 1px solid $primary-color-1;
										}
										
									}
								}	
							}
					}
					

					&__more-filter{

						
					//	margin-bottom: $base-margin;
					
			
						
						&__button{
							border-bottom: 1px solid $primary-color-1;
							padding: $base-padding/3 0;
							
							text-align: right;
							display: flex;
							align-items: flex-end;
							justify-content: flex-end;
							
							
							.menu-toggle-button{
								
								//padding-right: 10px;
								width: 125px;
								
								&::after{
									margin-left:0;
									margin-right: 0;
									//@include font-size(2.4);
									font-weight: 300;
								}
							}
						}
						
						
						&__content__inner{
							display: flex;
							justify-content: space-between;
							flex-direction: column;
							
							@include media-breakpoint-down(sm) {
								flex-direction: column;
							}
							
							.detail-filter{
								@include reset-list();
							//	max-width: 50%;
								@include media-breakpoint-down(lg) {
							//		max-width: 70%;
								}
				
								@include media-breakpoint-down(sm) {
									max-width: 100%;
								}
								
								padding: $base-padding $base-padding 0 0;
								&__item{
									display: inline-flex;
									margin-right: $base-margin;
									label{
										display: flex;
										justify-content: center;
										align-items: center;
										flex-direction: row;
										@include font-size(1.8);
										input{
											margin-right: $base-margin/3;
											border: 1px solid blue;
										}
									}
								}
								
								
							
							}
							&__buttons{
								display: flex;
								justify-content: space-between;
								margin-top:$base-margin;

								@include media-breakpoint-down(sm) {
									flex-direction: column;
									
									.button--with-icon{
										margin-top: $base-margin;
									}
								}
								
								

								
								
								.button--with-icon{
									color: $base-font-color;
									border: 1px solid $base-font-color;
									color: $base-font-color;
									max-width: 240px;
									i{
										color:$base-font-color;
									}
									&:hover,
									&:focus {
										color:$white;
										background-color: $primary-color-1;
										i{
											color:$white;
											
											&:after{
												background-color: $white;
											}
										}
										
									}
								}
								
							}
							

						}	
					}
				}	
			}			
		}
	}
}

.zeitungdetails__wrapper{
		@include make-row();
	margin-bottom: $base-margin;

	&__inner{
		
		@include setBasicModuleWidth-Medium();
		
		border: 1px solid green;	
		
		display: flex;
		flex-wrap: wrap;	
		.module-text{
		width: 33%;

	}
		
	}
}

.zeitungkompakt{
	
	
	
	
	
}


.directory-filter-selected{
	@include make-row();
	
	&__inner{
		
		@include setBasicModuleWidth-Medium();
		
		ul{
			@include reset-list();
			li{
				display: inline-flex;
				margin-right: $base-margin/2;
			}
		}
		
	}
	
}


.directory-filter-bibliothek{
	@include make-row();
	margin-bottom: $base-margin;

	&__inner{
		
		@include setBasicModuleWidth-Medium();
		
		
		
		&__content{
			@include make-row();
			
			&__inner{
				@include setBasicModuleWidth-Full();
				
				padding-bottom: $base-padding;
				
				.filter-form{
					margin-top: $base-margin;
					
					padding: $base-padding;
					padding-bottom: $base-padding/2;
					border-top:1px solid $base-font-color;
					border-bottom:1px solid $base-font-color; 

					
					&__basics{
						
						display: flex;
						justify-content: space-between;
						align-items: center;
						
						&__counter{
							color: $primary-color-1;
						}

						&__filter{
							display: flex;
							flex-wrap: wrap;
							
							&>div{
								margin: 0 0 $base-margin/2 $base-margin/2 ;
							}

						}
						
						
						nav{
							.dropdown{		
								button{
									width: 100%;
									text-align: left;
									justify-content: space-between;
								}		
							}
						}
					}
				}	
			}			
		}
	}
}

/* Template für Mitgliederverzeichnis*/
	
	.bdzv-mitgliederverzeichnis{
		
		@include make-row();
		margin-bottom: $base-margin*3;
	
		&__inner {
			@include setBasicModuleWidth-Medium();
			
			.row{
				margin-bottom: $base-margin;
				
				&--kontakt{
					margin-top: $base-margin*3;
				}
				
			}
			
			.col{
				@include make-col-ready();
			}
			
			.col-50{
			@include make-col(6);
			
			@include media-breakpoint-down(md) {
									@include make-col(12);
	}
			
			}
			
		}
		
		
		
		
		
		
	}

