.module-article-iframe {
	@include make-row();
	margin-bottom: $base-margin*3;
	
	&__inner {
		@include setBasicModuleWidth-Medium();
		
		.content-wrapper-two-columns & {
			@include setBasicModuleWidth-Full();
		}

			
		@include media-breakpoint-down(lg) {
		
		}
		
		@include media-breakpoint-down(md) {
		
		}
		
		@include media-breakpoint-down(sm) {
		
		}	
	}
}