.sammler-downloads-links {
	@include make-row();
	background-color: $grey-4;
	min-height: 375px;
	max-width: 450px;

	&__inner {
		@include setBasicModuleWidth-Full();
		padding: $base-padding;		
	}
}

.link-navigation{
	@include reset-list();
	display: flex;
	flex-direction: column;

	&__item{
		padding: $base-margin/2 0;	
		@include font-size(2.1);
			
	}
	
	.link-item{
		display: flex;
		justify-content: space-between;
		align-items: center;
		@include font-size($base-font-size);
		span{
			@include font-size(1.5);
			color: $grey-1;
			white-space: nowrap;
			margin-left: $base-margin;
			align-self: flex-end;
		}
	}
	
	&__link{
		position: relative;
		display: block;
		padding-left: 0;
		transition: all $base-duration $base-timing;
		
		&::before{
			position: absolute;
			content: "\f061";
			font-family: $fontawesome;
			left:-$base-margin;
			transition: all $base-duration $base-timing;
			font-weight: 300;
			opacity: 0;
			@include set-topic-color(color);
		}
		
		&:active,
		&:hover{
			font-family: $base-font-family-bold;
			padding-left: $base-padding*1.5;
			&::before{
				left:0;	
				opacity: 1;
			}	
		}	
	}
	&__description{
		@include font-size(1.7)
		border-top: 1px solid $grey-1;
		margin-bottom: 0;
		padding-top: $base-padding/2;
		margin-top:$base-margin/2;
	}
}

