.module-article-maginalspalte {
	@include make-row();
	margin-bottom: $base-margin*1.5;
	&__inner {
		@include setBasicModuleWidth-Full();
		
		p{
			@include font-size(1.5);
		}
		
	}

}

