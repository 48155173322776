.module-article-two-columns {
	@include make-row();
	margin-bottom: $base-margin*3;
	margin-top: $base-margin*2;
	//background-color: $grey-3;
	
	
	&__inner {
		@include setBasicModuleWidth-Medium();

		.content-row{
			@include make-row();
			padding-top: $base-padding*2;
			padding-bottom: $base-padding*2;
			
			&__left{
				@include make-col-ready();
				@include make-col(5.5);
			}
			&__right{
				@include make-col-ready();
				@include make-col(6);
				@include make-col-offset(0.5);
				padding-left: 4.2%;
				border-left: 4px solid $primary-color-1;	
				@include set-topic-border(border-left);
				//position: relative;	
				/*&::before{
					content: '';
					width:2px;
					height: 100%;
					background-color: red;
					position: absolute;
					top:0;
					left:-9.3333%;
				}*/		
			}
		}
		
		.button{
			margin-top: $base-margin;		
		}
		
		
		h2,h3,h4{
			font-family: $heading-font-family-style-2-bold;
			@include font-size($h6-font-size);
		}		
	}
}


@include media-breakpoint-down(md) {
	.module-article-two-columns {
		&__inner {
			.content-row{
				&__left{
					@include make-col(10);
					@include make-col-offset(1);
				}
				&__right{	
					@include make-col(10);
					@include make-col-offset(1);
					padding-left: 10px;
					border-left:0;
					@include set-topic-border(border-left, 0);
					border-top: 4px solid $primary-color-1;	
					@include set-topic-border(border-top);
					margin-top: $base-margin;
					padding-top: $base-padding*1.5;
				}
				
			}
		}
	}
}
@include media-breakpoint-down(sm) {
	.module-article-two-columns {
		&__inner {
			.content-row{
				&__right{	
					@include make-col(10);
					@include make-col-offset(1);
				}
			}
		}
	}
}



