// returns font-size and line-height in px and rem
@mixin font-size($size: 1.6, $line: $size * 1.5){
    font-size:   ($size * 10) + px;
    line-height: ($line * 10) + px;
    font-size:   $size + rem;
	line-height: $line + rem;
}

// reset ul-properties
@mixin reset-list() {
	list-style: none;
	margin: 0;
	padding: 0;
}


//set colors for different topics

@mixin set-topic-color($property_name, $opacity: 1) {
	$lightness: (1 - $opacity) * 100%;
	body.topic--primary-color-1 & {
		#{$property_name}: scale-color($primary-color-1, $lightness: $lightness);
	}
	body.topic--primary-color-2 & {
		#{$property_name}: scale-color($primary-color-2, $lightness: $lightness);
	}
	body.topic--primary-color-3 &{
		#{$property_name}: scale-color($primary-color-3, $lightness: $lightness);
	}
	body.topic--primary-color-4 &{
		#{$property_name}: scale-color($primary-color-4, $lightness: $lightness);
	}
	body.topic--secondary-color-1 & {
		#{$property_name}: scale-color($secondary-color-1, $lightness: $lightness);
	}
	body.topic--secondary-color-2 & {
		#{$property_name}: scale-color($secondary-color-2, $lightness: $lightness);
	}
	body.topic--secondary-color-3 &{
		#{$property_name}: scale-color($secondary-color-3, $lightness: $lightness);
	}
	body.topic--secondary-color-4 &{
		#{$property_name}: scale-color($secondary-color-4, $lightness: $lightness);
	}
	body.topic--secondary-color-5 & {
		#{$property_name}: scale-color($secondary-color-5, $lightness: $lightness);
	}
	body.topic--secondary-color-6 & {
		#{$property_name}: scale-color($secondary-color-6, $lightness: $lightness);
	}
	body.topic--secondary-color-7 & {
		#{$property_name}: scale-color($secondary-color-7, $lightness: $lightness);
	}
	body.topic--secondary-color-8 & {
		#{$property_name}: scale-color($secondary-color-8, $lightness: $lightness);
	}
	body.topic--secondary-color-9 & {
		#{$property_name}: scale-color($secondary-color-9, $lightness: $lightness);
	}
	body.topic--secondary-color-10 & {
		#{$property_name}: scale-color($secondary-color-10, $lightness: $lightness);
	}
	body.topic--secondary-color-11 & {
		#{$property_name}: scale-color($secondary-color-11, $lightness: $lightness);
	}
	body.topic--secondary-color-12 & {
		#{$property_name}: scale-color($secondary-color-12, $lightness: $lightness);
	}
	body.topic--secondary-color-13 & {
		#{$property_name}: scale-color($secondary-color-13, $lightness: $lightness);
	}
}

//set border for different topics

@mixin set-topic-border($property_name, $width: $base-border-width) {

	body.topic--primary-color-1 &,
	&.item--topic--primary-color-1 {
		#{$property_name}: $width solid $primary-color-1;
	}
	body.topic--primary-color-2 &,
	body.topic--primary-color-3 &,
	body.topic--primary-color-4 &,
	body.topic--secondary-color-1 &,
	body.topic--secondary-color-2 &,
	body.topic--secondary-color-3 &,
	body.topic--secondary-color-4 &,
	body.topic--secondary-color-5 &,
	body.topic--secondary-color-6 &,
	body.topic--secondary-color-7 &,
	body.topic--secondary-color-8 &,
	body.topic--secondary-color-9 &,
	body.topic--secondary-color-10 &,
	body.topic--secondary-color-11 &,
	body.topic--secondary-color-12 &,
	body.topic--secondary-color-13 &{
		&.item--topic--primary-color-1 {
		#{$property_name}: $width solid $primary-color-1;
		}
	}

	
	body.topic--primary-color-2 &,
	&.item--topic--primary-color-2 {
		#{$property_name}: $width solid $primary-color-2;
	}
	body.topic--primary-color-1 &,
	body.topic--primary-color-3 &,
	body.topic--primary-color-4 &,
	body.topic--secondary-color-1 &,
	body.topic--secondary-color-2 &,
	body.topic--secondary-color-3 &,
	body.topic--secondary-color-4 &,
	body.topic--secondary-color-5 &,
	body.topic--secondary-color-6 &,
	body.topic--secondary-color-7 &,
	body.topic--secondary-color-8 &,
	body.topic--secondary-color-9 &,
	body.topic--secondary-color-10 &,
	body.topic--secondary-color-11 &,
	body.topic--secondary-color-12 &,
	body.topic--secondary-color-13 &{
		&.item--topic--primary-color-2 {
		#{$property_name}: $width solid $primary-color-2;
		}
	}
	
	body.topic--primary-color-3 &,
	&.item--topic--primary-color-3 {
		#{$property_name}: $width solid $primary-color-3;
	}
	body.topic--primary-color-1 &,
	body.topic--primary-color-2 &,
	body.topic--primary-color-4 &,
	body.topic--secondary-color-1 &,
	body.topic--secondary-color-2 &,
	body.topic--secondary-color-3 &,
	body.topic--secondary-color-4 &,
	body.topic--secondary-color-5 &,
	body.topic--secondary-color-6 &,
	body.topic--secondary-color-7 &,
	body.topic--secondary-color-8 &,
	body.topic--secondary-color-9 &,
	body.topic--secondary-color-10 &,
	body.topic--secondary-color-11 &,
	body.topic--secondary-color-12 &,
	body.topic--secondary-color-13 &{
		&.item--topic--primary-color-3 {
		#{$property_name}: $width solid $primary-color-3;
		}
	}
	
	body.topic--primary-color-4 &,
	&.item--topic--primary-color-4 {
		#{$property_name}: $width solid $primary-color-4;
	}
	body.topic--primary-color-1 &,
	body.topic--primary-color-2 &,
	body.topic--primary-color-3 &,
	body.topic--secondary-color-1 &,
	body.topic--secondary-color-2 &,
	body.topic--secondary-color-3 &,
	body.topic--secondary-color-4 &,
	body.topic--secondary-color-5 &,
	body.topic--secondary-color-6 &,
	body.topic--secondary-color-7 &,
	body.topic--secondary-color-8 &,
	body.topic--secondary-color-9 &,
	body.topic--secondary-color-10 &,
	body.topic--secondary-color-11 &,
	body.topic--secondary-color-12 &,
	body.topic--secondary-color-13 &{
		&.item--topic--primary-color-4 {
		#{$property_name}: $width solid $primary-color-4;
		}
	}

	body.topic--secondary-color-1 &,
	&.item--topic--secondary-color-1 {
		#{$property_name}: $width solid $secondary-color-1;
	}
	body.topic--primary-color-1 &,
	body.topic--primary-color-2 &,
	body.topic--primary-color-3 &,
	body.topic--primary-color-4 &,
	body.topic--secondary-color-2 &,
	body.topic--secondary-color-3 &,
	body.topic--secondary-color-4 &,
	body.topic--secondary-color-5 &,
	body.topic--secondary-color-6 &,
	body.topic--secondary-color-7 &,
	body.topic--secondary-color-8 &,
	body.topic--secondary-color-9 &,
	body.topic--secondary-color-10 &,
	body.topic--secondary-color-11 &,
	body.topic--secondary-color-12 &,
	body.topic--secondary-color-13 &{
		&.item--topic--secondary-color-1 {
		#{$property_name}: $width solid $secondary-color-1;
		}
	}
	
	body.topic--secondary-color-2 &,
	&.item--topic--secondary-color-2 {
		#{$property_name}: $width solid $secondary-color-2;
	}
	body.topic--primary-color-1 &,
	body.topic--primary-color-2 &,
	body.topic--primary-color-3 &,
	body.topic--primary-color-4 &,
	body.topic--secondary-color-1 &,
	body.topic--secondary-color-3 &,
	body.topic--secondary-color-4 &,
	body.topic--secondary-color-5 &,
	body.topic--secondary-color-6 &,
	body.topic--secondary-color-7 &,
	body.topic--secondary-color-8 &,
	body.topic--secondary-color-9 &,
	body.topic--secondary-color-10 &,
	body.topic--secondary-color-11 &,
	body.topic--secondary-color-12 &,
	body.topic--secondary-color-13 &{
		&.item--topic--secondary-color-2 {
		#{$property_name}: $width solid $secondary-color-2;
		}
	}
	
	body.topic--secondary-color-3 &,
	&.item--topic--secondary-color-3 {
		#{$property_name}: $width solid $secondary-color-3;
	}
	body.topic--primary-color-1 &,
	body.topic--primary-color-2 &,
	body.topic--primary-color-3 &,
	body.topic--primary-color-4 &,
	body.topic--secondary-color-1 &,
	body.topic--secondary-color-2 &,
	body.topic--secondary-color-4 &,
	body.topic--secondary-color-5 &,
	body.topic--secondary-color-6 &,
	body.topic--secondary-color-7 &,
	body.topic--secondary-color-8 &,
	body.topic--secondary-color-9 &,
	body.topic--secondary-color-10 &,
	body.topic--secondary-color-11 &,
	body.topic--secondary-color-12 &,
	body.topic--secondary-color-13 &{
		&.item--topic--secondary-color-3 {
		#{$property_name}: $width solid $secondary-color-3;
		}
	}
	
	body.topic--secondary-color-4 &,
	&.item--topic--secondary-color-4 {
		#{$property_name}: $width solid $secondary-color-4;
	}
	body.topic--primary-color-1 &,
	body.topic--primary-color-2 &,
	body.topic--primary-color-3 &,
	body.topic--primary-color-4 &,
	body.topic--secondary-color-1 &,
	body.topic--secondary-color-2 &,
	body.topic--secondary-color-3 &,
	body.topic--secondary-color-5 &,
	body.topic--secondary-color-6 &,
	body.topic--secondary-color-7 &,
	body.topic--secondary-color-8 &,
	body.topic--secondary-color-9 &,
	body.topic--secondary-color-10 &,
	body.topic--secondary-color-11 &,
	body.topic--secondary-color-12 &,
	body.topic--secondary-color-13 &{
		&.item--topic--secondary-color-4 {
		#{$property_name}: $width solid $secondary-color-4;
		}
	}
	
	body.topic--secondary-color-5 &,
	&.item--topic--secondary-color-5 {
		#{$property_name}: $width solid $secondary-color-5;
	}
	body.topic--primary-color-1 &,
	body.topic--primary-color-2 &,
	body.topic--primary-color-3 &,
	body.topic--primary-color-4 &,
	body.topic--secondary-color-1 &,
	body.topic--secondary-color-2 &,
	body.topic--secondary-color-3 &,
	body.topic--secondary-color-4 &,
	body.topic--secondary-color-6 &,
	body.topic--secondary-color-7 &,
	body.topic--secondary-color-8 &,
	body.topic--secondary-color-9 &,
	body.topic--secondary-color-10 &,
	body.topic--secondary-color-11 &,
	body.topic--secondary-color-12 &,
	body.topic--secondary-color-13 &{
		&.item--topic--secondary-color-5 {
		#{$property_name}: $width solid $secondary-color-5;
		}
	}
	
	body.topic--secondary-color-6 &,
	&.item--topic--secondary-color-6 {
		#{$property_name}: $width solid $secondary-color-6;
	}
	body.topic--primary-color-1 &,
	body.topic--primary-color-2 &,
	body.topic--primary-color-3 &,
	body.topic--primary-color-4 &,
	body.topic--secondary-color-1 &,
	body.topic--secondary-color-2 &,
	body.topic--secondary-color-3 &,
	body.topic--secondary-color-4 &,
	body.topic--secondary-color-5 &,
	body.topic--secondary-color-7 &,
	body.topic--secondary-color-8 &,
	body.topic--secondary-color-9 &,
	body.topic--secondary-color-10 &,
	body.topic--secondary-color-11 &,
	body.topic--secondary-color-12 &,
	body.topic--secondary-color-13 &{
		&.item--topic--secondary-color-6 {
		#{$property_name}: $width solid $secondary-color-6;
		}
	}
	
	body.topic--secondary-color-7 &,
	&.item--topic--secondary-color-7 {
		#{$property_name}: $width solid $secondary-color-7;
	}
	body.topic--primary-color-1 &,
	body.topic--primary-color-2 &,
	body.topic--primary-color-3 &,
	body.topic--primary-color-4 &,
	body.topic--secondary-color-1 &,
	body.topic--secondary-color-2 &,
	body.topic--secondary-color-3 &,
	body.topic--secondary-color-4 &,
	body.topic--secondary-color-5 &,
	body.topic--secondary-color-6 &,
	body.topic--secondary-color-8 &,
	body.topic--secondary-color-9 &,
	body.topic--secondary-color-10 &,
	body.topic--secondary-color-11 &,
	body.topic--secondary-color-12 &,
	body.topic--secondary-color-13 &{
		&.item--topic--secondary-color-7 {
		#{$property_name}: $width solid $secondary-color-7;
		}
	}
	
	body.topic--secondary-color-8 &,
	&.item--topic--secondary-color-8 {
		#{$property_name}: $width solid $secondary-color-8;
	}
	body.topic--primary-color-1 &,
	body.topic--primary-color-2 &,
	body.topic--primary-color-3 &,
	body.topic--primary-color-4 &,
	body.topic--secondary-color-1 &,
	body.topic--secondary-color-2 &,
	body.topic--secondary-color-3 &,
	body.topic--secondary-color-4 &,
	body.topic--secondary-color-5 &,
	body.topic--secondary-color-6 &,
	body.topic--secondary-color-7 &,
	body.topic--secondary-color-9 &,
	body.topic--secondary-color-10 &,
	body.topic--secondary-color-11 &,
	body.topic--secondary-color-12 &,
	body.topic--secondary-color-13 &{
		&.item--topic--secondary-color-8 {
		#{$property_name}: $width solid $secondary-color-8;
		}
	}
	
	body.topic--secondary-color-9 &,
	&.item--topic--secondary-color-9 {
		#{$property_name}: $width solid $secondary-color-9;
	}
	body.topic--primary-color-1 &,
	body.topic--primary-color-2 &,
	body.topic--primary-color-3 &,
	body.topic--primary-color-4 &,
	body.topic--secondary-color-1 &,
	body.topic--secondary-color-2 &,
	body.topic--secondary-color-3 &,
	body.topic--secondary-color-4 &,
	body.topic--secondary-color-5 &,
	body.topic--secondary-color-6 &,
	body.topic--secondary-color-7 &,
	body.topic--secondary-color-8 &,
	body.topic--secondary-color-10 &,
	body.topic--secondary-color-11 &,
	body.topic--secondary-color-12 &,
	body.topic--secondary-color-13 &{
		&.item--topic--secondary-color-9 {
		#{$property_name}: $width solid $secondary-color-9;
		}
	}
	
	body.topic--secondary-color-10 &,
	&.item--topic--secondary-color-10 {
		#{$property_name}: $width solid $secondary-color-10;
	}
	body.topic--primary-color-1 &,
	body.topic--primary-color-2 &,
	body.topic--primary-color-3 &,
	body.topic--primary-color-4 &,
	body.topic--secondary-color-1 &,
	body.topic--secondary-color-2 &,
	body.topic--secondary-color-3 &,
	body.topic--secondary-color-4 &,
	body.topic--secondary-color-5 &,
	body.topic--secondary-color-6 &,
	body.topic--secondary-color-7 &,
	body.topic--secondary-color-8 &,
	body.topic--secondary-color-11 &,
	body.topic--secondary-color-12 &,
	body.topic--secondary-color-13 &{
		&.item--topic--secondary-color-10 {
		#{$property_name}: $width solid $secondary-color-10;
		}
	}
	
	body.topic--secondary-color-11 &,
	&.item--topic--secondary-color-11 {
		#{$property_name}: $width solid $secondary-color-11;
	}
	body.topic--primary-color-1 &,
	body.topic--primary-color-2 &,
	body.topic--primary-color-3 &,
	body.topic--primary-color-4 &,
	body.topic--secondary-color-1 &,
	body.topic--secondary-color-2 &,
	body.topic--secondary-color-3 &,
	body.topic--secondary-color-4 &,
	body.topic--secondary-color-5 &,
	body.topic--secondary-color-6 &,
	body.topic--secondary-color-7 &,
	body.topic--secondary-color-8 &,
	body.topic--secondary-color-10 &,
	body.topic--secondary-color-12 &,
	body.topic--secondary-color-13 &{
		&.item--topic--secondary-color-11 {
		#{$property_name}: $width solid $secondary-color-11;
		}
	}
	
	body.topic--secondary-color-12 &,
	&.item--topic--secondary-color-12 {
		#{$property_name}: $width solid $secondary-color-12;
	}
	body.topic--primary-color-1 &,
	body.topic--primary-color-2 &,
	body.topic--primary-color-3 &,
	body.topic--primary-color-4 &,
	body.topic--secondary-color-1 &,
	body.topic--secondary-color-2 &,
	body.topic--secondary-color-3 &,
	body.topic--secondary-color-4 &,
	body.topic--secondary-color-5 &,
	body.topic--secondary-color-6 &,
	body.topic--secondary-color-7 &,
	body.topic--secondary-color-8 &,
	body.topic--secondary-color-10 &,
	body.topic--secondary-color-11 &,
	body.topic--secondary-color-13 &{
		&.item--topic--secondary-color-12 {
		#{$property_name}: $width solid $secondary-color-12;
		}
	}
	
	body.topic--secondary-color-13 &,
	&.item--topic--secondary-color-13 {
		#{$property_name}: $width solid $secondary-color-13;
	}
	body.topic--primary-color-1 &,
	body.topic--primary-color-2 &,
	body.topic--primary-color-3 &,
	body.topic--primary-color-4 &,
	body.topic--secondary-color-1 &,
	body.topic--secondary-color-2 &,
	body.topic--secondary-color-3 &,
	body.topic--secondary-color-4 &,
	body.topic--secondary-color-5 &,
	body.topic--secondary-color-6 &,
	body.topic--secondary-color-7 &,
	body.topic--secondary-color-8 &,
	body.topic--secondary-color-10 &,
	body.topic--secondary-color-11 &,
	body.topic--secondary-color-12 &{
		&.item--topic--secondary-color-13 {
		#{$property_name}: $width solid $secondary-color-13;
		}
	}
	
}


@mixin set-item-color($property_name, $opacity: 1) {
	$lightness: (1 - $opacity) * 100%;
	&.item--topic--primary-color-1  {
		#{$property_name}: scale-color($primary-color-1, $lightness: $lightness);
	}
	&.item--topic--primary-color-2  {
		#{$property_name}: scale-color($primary-color-2, $lightness: $lightness);
	}
	&.item--topic--primary-color-3  {
		#{$property_name}: scale-color($primary-color-3, $lightness: $lightness);
	}
	&.item--topic--primary-color-4  {
		#{$property_name}: scale-color($primary-color-4, $lightness: $lightness);
	}
	&.item--topic--secondary-color-1  {
		#{$property_name}: scale-color($secondary-color-1, $lightness: $lightness);
	}
	&.item--topic--secondary-color-2  {
		#{$property_name}: scale-color($secondary-color-2, $lightness: $lightness);
	}
	&.item--topic--secondary-color-3  {
		#{$property_name}: scale-color($secondary-color-3, $lightness: $lightness);
	}
	&.item--topic--secondary-color-4  {
		#{$property_name}: scale-color($secondary-color-4, $lightness: $lightness);
	}
	&.item--topic--secondary-color-5  {
		#{$property_name}: scale-color($secondary-color-5, $lightness: $lightness);
	}
	&.item--topic--secondary-color-6  {
		#{$property_name}: scale-color($secondary-color-6, $lightness: $lightness);
	}
	&.item--topic--secondary-color-7  {
		#{$property_name}: scale-color($secondary-color-7, $lightness: $lightness);
	}
	&.item--topic--secondary-color-8  {
		#{$property_name}: scale-color($secondary-color-8, $lightness: $lightness);
	}
	&.item--topic--secondary-color-9  {
		#{$property_name}: scale-color($secondary-color-9, $lightness: $lightness);
	}
	&.item--topic--secondary-color-10  {
		#{$property_name}: scale-color($secondary-color-10, $lightness: $lightness);
	}
	&.item--topic--secondary-color-11  {
		#{$property_name}: scale-color($secondary-color-11, $lightness: $lightness);
	}
	&.item--topic--secondary-color-12  {
		#{$property_name}: scale-color($secondary-color-12, $lightness: $lightness);
	}
	&.item--topic--secondary-color-13  {
		#{$property_name}: scale-color($secondary-color-13, $lightness: $lightness);
	}
}


@mixin set-pseudo-item-color($property_name, $opacity: 1) {
	$lightness: (1 - $opacity) * 100%;
	&--pseudo-item--topic--primary-color-1::before  {
		#{$property_name}: scale-color($primary-color-1, $lightness: $lightness);
	}
	&--pseudo-item--topic--primary-color-2::before  {
		#{$property_name}: scale-color($primary-color-2, $lightness: $lightness);
	}
	&--pseudo-item--topic--primary-color-3::before  {
		#{$property_name}: scale-color($primary-color-3, $lightness: $lightness);
	}
	&--pseudo-item--topic--primary-color-4::before  {
		#{$property_name}: scale-color($primary-color-4, $lightness: $lightness);
	}
	&--pseudo-item--topic--secondary-color-1::before  {
		#{$property_name}: scale-color($secondary-color-1, $lightness: $lightness);
	}
	&--pseudo-item--topic--secondary-color-2::before  {
		#{$property_name}: scale-color($secondary-color-2, $lightness: $lightness);
	}
	&--pseudo-item--topic--secondary-color-3::before  {
		#{$property_name}: scale-color($secondary-color-3, $lightness: $lightness);
	}
	&--pseudo-item--topic--secondary-color-4::before  {
		#{$property_name}: scale-color($secondary-color-4, $lightness: $lightness);
	}
	&--pseudo-item--topic--secondary-color-5::before  {
		#{$property_name}: scale-color($secondary-color-5, $lightness: $lightness);
	}
	&--pseudo-item--topic--secondary-color-6::before  {
		#{$property_name}: scale-color($secondary-color-6, $lightness: $lightness);
	}
	&--pseudo-item--topic--secondary-color-7::before  {
		#{$property_name}: scale-color($secondary-color-7, $lightness: $lightness);
	}
	&--pseudo-item--topic--secondary-color-8::before  {
		#{$property_name}: scale-color($secondary-color-8, $lightness: $lightness);
	}
	&--pseudo-item--topic--secondary-color-9::before  {
		#{$property_name}: scale-color($secondary-color-9, $lightness: $lightness);
	}
	&--pseudo-item--topic--secondary-color-10::before  {
		#{$property_name}: scale-color($secondary-color-10, $lightness: $lightness);
	}
	&--pseudo-item--topic--secondary-color-11::before  {
		#{$property_name}: scale-color($secondary-color-11, $lightness: $lightness);
	}
	&--pseudo-item--topic--secondary-color-12::before  {
		#{$property_name}: scale-color($secondary-color-12, $lightness: $lightness);
	}
	&--pseudo-item--topic--secondary-color-13::before  {
		#{$property_name}: scale-color($secondary-color-13, $lightness: $lightness);
	}
}






//set general module-width for content-elements

@mixin setBasicModuleWidth-ExtraSlim(){
	
	@include make-col-ready();
				
	@include make-col(8);
    @include make-col-offset(2);

	@include media-breakpoint-down(xl) {  }
	@include media-breakpoint-down(lg) {  }
	@include media-breakpoint-down(md) { 
		@include make-col(12);
		@include make-col-offset(0)
	}
	@include media-breakpoint-down(sm) {  }
	@include media-breakpoint-down(xs) {  }
	
}

@mixin setBasicModuleWidth-Slim(){
	
	@include make-col-ready();
				
	@include make-col(9);
    @include make-col-offset(1.5);

	@include media-breakpoint-down(xl) {  }
	@include media-breakpoint-down(lg) {  }
	@include media-breakpoint-down(md) { 
		@include make-col(12);
		@include make-col-offset(0)
	}
	@include media-breakpoint-down(sm) {  }
	@include media-breakpoint-down(xs) {  }
	
}

@mixin setBasicModuleWidth-Medium(){
	
	@include make-col-ready();
				
	@include make-col(10);
    @include make-col-offset(1);

	@include media-breakpoint-down(xl) {  }
	@include media-breakpoint-down(lg) {  }
	@include media-breakpoint-down(md) { 
		@include make-col(12);
		@include make-col-offset(0)
	}
	@include media-breakpoint-down(sm) {  }
	@include media-breakpoint-down(xs) {  }
	
}

@mixin setBasicModuleWidth-MediumLarge(){
	
	@include make-col-ready();
				
	@include make-col(10.5);
    @include make-col-offset(0.75);

	@include media-breakpoint-down(xl) {  }
	@include media-breakpoint-down(lg) {  }
	@include media-breakpoint-down(md) { 
		@include make-col(12);
		@include make-col-offset(0)
	}
	@include media-breakpoint-down(sm) {  }
	@include media-breakpoint-down(xs) {  }
}

@mixin setBasicModuleWidth-Large(){
	
	@include make-col-ready();
				
	@include make-col(11);
    @include make-col-offset(0.5);

	@include media-breakpoint-down(xl) {  }
	@include media-breakpoint-down(lg) {  }
	@include media-breakpoint-down(md) { 
		@include make-col(12);
		@include make-col-offset(0)
	}
	@include media-breakpoint-down(sm) {  }
	@include media-breakpoint-down(xs) {  }
}

@mixin setBasicModuleWidth-Full(){
	
	@include make-col-ready();
				
	@include make-col(12);
    @include make-col-offset(0);

    @include media-breakpoint-down(xl) {  }
	@include media-breakpoint-down(lg) {  }
	@include media-breakpoint-down(md) {  }
	@include media-breakpoint-down(sm) {  }
	@include media-breakpoint-down(xs) {  }
	
}

@mixin sliderNavi($slider-single: false) {
	
	position: relative;

	.owl-stage-outer {
		overflow-x: hidden;
		
	}
	
	.owl-dots{
		background-color: $grey-3;
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		justify-content: center;
		padding: $base-padding;
		margin-top: $base-margin;
		
		.owl-dot{
			background-color: $grey-2;
			border:0;
			height: 15px;
			width: 15px;
			margin: 0 $base-margin/2;
			border-radius:50%;
			outline: 0;	
			padding: 0;
			&.active{
				background-color: $grey-1;
			}
			
		}
		&.disabled {
			display: none;
		}
	}

	.owl-nav {
		
		display: flex;
		position: absolute;
		transition: opacity $base-duration $base-timing;
		opacity: 0.7;
		
		@if $slider-single {
			justify-content: space-between;
			top:50%;
			left:0;
			width: 100%;
			margin-top: -15px;
		} @else {
			justify-content: flex-end;
			top:-$base-margin*2;
			right:$base-margin/2;
		}
		

		.owl-next,
		.owl-prev {
			position: relative;
			height: 30px;
			border: 0;
			outline: none;
			cursor: pointer;
			transition: color $base-duration $base-timing;
				background-color: #fff;
				background-color: transparent;
				color: $base-font-color;
				&.disabled, &[disabled] {
					color: rgba($grey-1, .3);
					cursor: not-allowed;
				}
			

			span {
				display: none;
			}
		}

		.owl-prev {
			@if $slider-single {
				right:70px;
				.content-wrapper-two-columns & {
					right:50px;
				}
				@include media-breakpoint-down(lg) {
					.content-wrapper-two-columns & {
						right:45px;
					}
				}
			}
			&::before {
				@if $slider-single {
					content: '\f053';
				} @else {
					content: '\f060';
				}
				font-family: $fontawesome;
				font-weight: 300;
				@include font-size(3.0,2.8);
			}
		}

		.owl-next {
			@if $slider-single {
				left:70px;
				.content-wrapper-two-columns & {
					left:50px;
				}
				@include media-breakpoint-down(lg) {
					.content-wrapper-two-columns & {
						left:45px;
					}
				}
			}
			&::after {
				@if $slider-single {
					content: '\f054';
				} @else {
					content: '\f061';
				}
				font-family: $fontawesome;
				font-weight: 300;
				@include font-size(3.0,2.8);
			}
		}

		&:hover {
			opacity: 1;
		}

		&.disabled {
			display: none;
		}
	}
}
