.article-teaser-news-wrapper,
.article-teaser-standard-wrapper,
.article-teaser-veranstaltung-wrapper,
.article-teaser-wrapper{
	 
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	height: 100%;
		
	&__content{
		background-color: $white;
		flex: 1;
		transition: all $base-duration $base-timing;
		
		&__link{
			display: block;
			color: $base-font-color;
			height: 100%;

			&:hover{

				color: $base-font-color;
				
				p.button{
					border: 1px solid $primary-color-2;
					background-color: $grey-3;
					margin-right: -5px;
				}
			}
			
			&__inner{
				
				height: 100%;
				display: flex;
				
				&__image{
					flex:50%;
				}
				
				&__text{
					flex: 50%;
					display: flex;
					flex-direction: column;
					justify-content: space-between;

					p:last-child(){
						margin-bottom: 0;
					}
					
					p{
						@include font-size(1.6);
					}
					
					h3{
						font-family: $heading-font-family-style-2-bold;
						@include font-size($h6-font-size);
					}
				}
				
				&--reverse{
					flex-direction: row-reverse;
				}
				
				&--big{
					
					&__image{	
						flex:33%;
					}
					&__text{
						flex:66%;
					}
				}
				
				&--small{
					
					flex-flow: column nowrap;
					
					&__image{	
						flex:auto;

					}
					&__text{
						padding: $base-padding;
						flex:100%;
						
						h3{
							font-family: $heading-font-family-style-1;
						}
					}
				}
			}
		}
	}
}



.teaser-text-header{
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	
	padding: $base-padding/2 0;
	margin-bottom: $base-margin;
	@include font-size(1.3);
	
	&>span{
		text-transform: uppercase;
	}
}

.teaser-text-main{
	flex-grow: 2;
}

.teaser-text-footer{
	display: flex;
	justify-content: space-between;
	align-items: center;
	text-align: right;
	margin-top: $base-margin;
	
	.button{
		white-space: nowrap;
	}
	
	&>span{
		display: flex;
		align-items: center;
		color: $primary-color-2;
		i{
			display:block;
			width: 25px;
			height:25px;
			margin-right: $base-margin/2;
			border-radius: 50%;
			@include set-item-color(background-color,1);
		}
	}
}


.article-teaser-standard-wrapper,
.article-teaser-wrapper{
	
	&__content{

		border:1px solid $grey-2;
		
		&:hover{
			border:1px solid $grey-1;
		}
		
		&__link{
			&__inner{
				&__text{
					padding: $base-padding/2  $base-padding $base-padding $base-padding;
				}
			}
		}
		
		.teaser-text-header{
			border-left-width: 0;
			border-right-width: 0;
			border-top-width: 0;
			border-bottom-width: 2px;
			border-style: solid;
			@include set-topic-border(border-bottom, 4px);
		}
	}
}

.article-teaser-veranstaltung-wrapper,
.article-teaser-news-wrapper{
	&__content{
		border-left:1px solid $grey-2;
		
		&:hover{
			border-left:1px solid $grey-1;
		}
		
		&__link{
			&__inner{
				&__text{
					padding: 0  $base-padding*1.5 $base-padding $base-padding*1.5;
					
				}
			}
			&:hover{
				p.button{
					margin-left:5px;
				}
			}
		}
	}
	.teaser-text-header{
		position: relative;

		&::before{
			content:'';
			width: $base-margin;
			position: absolute;
			left:-$base-margin*1.5;
			top:0;
			height:$base-margin*2;
			background-color: $grey-2;
			border-bottom-right-radius: $base-border-radius/2;
			border-top-right-radius: $base-border-radius/2;
			
		}
		@include set-pseudo-item-color(background-color);
	}
	.teaser-text-footer{
		
	}
}

.article-teaser-veranstaltung-wrapper{
	&__content{	
		&__link{
			&__inner{
				flex-direction: column;
				&__text{
					h3{
						border-left-width: 0;
						border-right-width: 0;
						border-top-width: 0;
						border-bottom-width: 2px;
						border-style: solid;
						padding-bottom: $base-padding/2;
						border-color: red;
						@include set-topic-border(border-bottom,2px);
					}
				}
				&__image{
					flex: none;
				}
			}
		}
	}
	.teaser-text-footer{
		
		@include media-breakpoint-down(lg) {
			flex-direction: column;
		}
		
		@include media-breakpoint-down(md) {
			flex-direction: row;
		}
		
		@include media-breakpoint-down(sm) {
			flex-direction: column;
		}

		@include media-breakpoint-down(xs) {
			flex-direction: row;
		}
		
		
		&>span{
			color: $base-font-color;	
			text-transform: uppercase;
		//	@include font-size(1.3);
		//	font-family: $base-font-family-bold;
			text-align: left;
		}
	}
}

.article-teaser-bild-wrapper-link{
	display: block;
	color: $white;
	height: 100%;

	&:hover{

		color: $white;
		
		p.button{
			border: 1px solid $primary-color-2;
			background-color: $grey-3;
			margin-right: -5px;
		}
		

			i{
				color:$primary-color-2;
			}
			

	}
}

.stage-teaser{
	.article-teaser-bild-wrapper{
		padding: $base-padding/2 $base-padding;
		display: flex;
		justify-content: space-between;
		flex-direction: column;		
		height: 100%;
		
		h3{
			font-family: $heading-font-family-style-2-bold;
			@include font-size($h6-font-size);
			text-align: left;
			display: block;
		}
		
		.teaser-text-header{
			border-left-width: 0;
			border-right-width: 0;
			border-top-width: 0;
			border-bottom-width: 2px;
			border-style: solid;
			@include set-topic-border(border-bottom, 2px);
		}
	}
}

