html{
	font-size: 62.5%;
}

body {
  @include font-size($base-font-size,$base-line-height);
  font-family: $base-font-family-light;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  	font-family: $heading-font-family;
  	font-size: $base-font-size;
  	line-height: $heading-line-height;
  	margin-bottom: $base-margin;

}

a:not([href]):not([tabindex]){
	color:$white;
	&:hover{
		color: $white;
	}
}

h1 {
	@include font-size($h1-font-size,$h1-font-size);
}
h2 {
	@include font-size($h2-font-size, 5.2);
}
h3{
	@include font-size($h3-font-size, $h2-font-size);
}
h4{
	@include font-size($h4-font-size,$h2-font-size);
}
h5{
	@include font-size($h5-font-size,$h3-font-size);
}
h6{
	@include font-size($h6-font-size,$h4-font-size);
}

/*
h1.headline--with-line,
h2.headline--with-line,
h3.headline--with-line,
h4.headline--with-line,
h5.headline--with-line,
h6.headline--with-line{
	
	padding-left: -70px;
	
	position:relative;
	
	&::before{
		content: '';
		background-color: $black;
		width:50px;
		height: 1px;
		position: absolute;
		left: -70px;
		//left: 0px;
		top:23px;
	}
}

h5.headline--with-line,
h6.headline--with-line{
	&::before{
		top:12px;
	}
}
*/

/*
.module-wrapper {

h1,h2,h3,h4,h5,h6{
	
	//margin-bottom: $base-margin;
	
	span{
		display: block;
		text-transform: uppercase;
		@include font-size(1.5);
		margin-top: $base-margin/1.5;
		font-family: $heading-font-family;
		margin-left: 2px;
	}
}

}
*/
/*
h2.headline--with-line{
	&::before{
		content: '';
		background-color: $black;
		width:50px;
		height: 4px;
		position: absolute;
		left: -70px;
		//left: 0px;
		top:23px;
	}
}
*/


/*
h1.headline--with-line{
	&::before{
		top:23px;
	}
}

h2.headline--with-line{
	&::before{
		top:19px;
	}
}

h3.headline--with-line{
	&::before{
		top:15px;
	}
}

h4.headline--with-line{
	&::before{
		top:17px;
	}
}

h6.overline{
	@include font-size(1.3);
	border-bottom: 1px solid $base-font-color;
	padding-bottom: $base-padding/2;
	text-transform: uppercase;
	font-family: $base-font-family-bold;
	margin-bottom: $base-margin;
}
*/

.results-highlight,
strong,b{
	font-family:$base-font-family-bold;
}


div.outer-line{
	border-left: $base-border-width solid $base-font-color;
	padding-left: $base-padding;	
	
	@include set-topic-border(border-left);
}

p {
  margin: 0 0 $small-spacing;
}

.lead,
.intro,
p.intro,
p.lead,
p.text-intro{
	font-family: $base-font-family-bold;
}

.align-center{
	text-align: center;
}

.icon-style{
	&::before{
		display: inline-block;
		content:'\f001';
		font-family: $fontawesome;
		margin-right: $base-margin/4;
		font-weight: 400;
		width: 20px;
	} 

	&--calendar{
		&::before{
			content:'\f073';
		}
	}
	&--microphone{
		&::before{
			content:'\f130';
		}
	}
	&--user{
		&::before{
			content:'\f007';
		}
	}
	&--pencil{
		&::before{
			content:'\f305';
			font-weight: 600;
		}
	}
}

date{
	font-family: $base-font-family;
	@include font-size(1.3);
}

p.text-small,
p.small{
	@include font-size(1.4);
}

a {
  
  color: $base-link-color;
  text-decoration: none;
  transition: color $base-duration $base-timing;

  &:active,
  &:focus,
  &:hover {
    color: $primary-color-3;
    text-decoration: none;
  }
  
  &.link{ 
	display: inline-block;
	&::after{
		content:'\f0c1';
		font-family: $fontawesome;
		margin-right: $base-margin/6;
		margin-left: $base-margin/6;
		@include font-size(1.5);
		font-weight: 200;
	}  
  }
  
  &.link--iconright{ 
	display: inline-block;
	&::after{
		content:'\f0c1';
		font-family: $fontawesome;
		margin-right: $base-margin/6;
		margin-left: $base-margin/6;
		@include font-size(1.5);
		font-weight: 400;
	}  
  }
  &.link--margin-right{
	  &::before{
		  margin-right: $base-margin/1.5;
		  margin-left: $base-margin/2;
		  @include font-size(1.8);
	  }
  }
  &.link--pdf{ 
	&::before{
		content:'\f1c1';
		font-weight: 600;
	}  
  }
  &.link--external{ 
	&::before{
		content:'\f35d';
		font-weight: 400;
	}  
  }
  &.link--iconright--external{ 
	&::after{
		content:'\f35d';
		font-weight: 400;
	}  
  }
  &.link--download{ 
	&::after{
		content:'\f063';
		font-weight: 200;
	}  
  }
  &.link--iconright--download{ 
	&::after{
		content:'\f019';
		font-weight: 600;
	}  
  }
  &.link--email{ 
	&::before{
		content:'\f0e0';
		font-weight: 400;
	}  
  }
  
  &.link--addtocalendar{
	  &::before{
		content:'\f271';
		font-weight: 400;
	}
  }

  &.link--iconright--addtocalendar{
	  &::after{
		content:'\f271';
		font-weight: 400;
	}
  }

  
  &.link--profil{ 
	&::before{
		content:'\f007';
		font-weight: 600;
	}  
  }
  &.link--interview{ 
	&::before{
		content:'\f130';
		font-weight: 600;
	//	margin-left: $base-margin/4;
		margin-right: $base-margin/1.2;
	}  
  }
  &.link--text{ 
	&::before{
		content:'\f305';
		font-weight: 600;	
		margin-right: $base-margin/1.7;	
	}  
  }
}






