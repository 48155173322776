.module-article-chart {
	@include make-row();
	margin-bottom: $base-margin*3;
	
	&__inner {
		@include setBasicModuleWidth-ExtraSlim();
		
		.content-wrapper-two-columns & {
			@include setBasicModuleWidth-Full();
		}	

		
		canvas{

		}

			
		.quelle{
			text-align: right;
			@include font-size(1.4);
			margin-top:$base-margin;
			color:$grey-1;
		}	
			
		@include media-breakpoint-down(lg) {
		
		}
		
		@include media-breakpoint-down(md) {
		
		}
		
		@include media-breakpoint-down(sm) {
		
		}	
	
	
	
	}
}


.has-chart {

	.flex-cols {
		display: flex;
	}

	.chartContainer {
		flex: 1 1 50%;
		position: relative;
	}

}