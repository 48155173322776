.module-article-preistraeger {
	@include make-row();
	margin-bottom: $base-margin*3;
	
	&__inner {
		@include setBasicModuleWidth-Medium();
		
		.preistraeger-wrapper{
			@include make-row();
			margin-bottom: $base-margin*2;
						
			&__image{
				@include make-col-ready();
				@include make-col(4);
				&__wrapper{
					
					background: $white url('../Images/Backgrounds/background-dots.svg') right bottom repeat;
					padding-right:$base-margin*1.5;
					padding-bottom: $base-padding*1.5;
					border-top:30px solid $white;
					margin-left: $base-margin*1.5;
					background-size: 10px;
					&__inner{
						margin-left: -$base-padding*1.5;
						margin-top:-$base-margin*1.5;
					}
				}
				
			}
			&__description{
				@include make-col-ready();
				@include make-col(4);
				@include make-col-offset(0.5);
				
				display: flex;
				flex-direction: column;
				justify-content: space-between;				
				
				h4{
					margin-bottom: $base-margin/2;
					font-family: $heading-font-family-style-2;
					word-break: break-word;

				}
				
				p.author{
					margin-top: $base-margin*1.5;
					margin-bottom: $base-margin/2;
					font-family: $base-font-family-bold;
				}
				
				
				.preistraeger-navigation{
					@include reset-list();
					display: flex;
					flex-direction: column;
					justify-content: center;
				
					&__item{
						padding: $base-margin/2 0;	
						border-bottom: 1px solid $grey-1;	
					}
				}
				
			}
			&__article-image{
				@include make-col-ready();
				@include make-col(3);
				@include make-col-offset(0.5);
			}	
		}
			
		@include media-breakpoint-down(md) {
			
			.preistraeger-wrapper{

				&__image{
					
				}
				
				&__description{
					@include make-col(5);
					@include make-col-offset(0);
				}
				
				&__article-image{
					@include make-col-offset(0);
				}
			}
		}
		
		@include media-breakpoint-down(sm) {
			.preistraeger-wrapper{

				&__image{
					@include make-col(12);
				}
				
				&__description{
					@include make-col(12);
					@include make-col-offset(0);
					margin-top: $base-margin;
				}
				
				&__article-image{
					@include make-col-offset(0);
					@include make-col(8);
					margin-top: $base-margin;
				}
			}
		}	
	}
}