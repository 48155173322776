//src: http://maddesigns.de/responsive-iframes-2417.html
.embed-video-container {
	position: relative; 
	padding-bottom: 56.25%; /* ratio 16x9 */
	height: 0; 
	overflow: hidden; 
	width: 100%;
	height: auto;
	z-index: 1;
	.iframe,
	iframe {
		position: absolute; 
		top: 0; 
		left: 0; 
		width: 100%; 
		height: 100%; 
	
		
	}
	
	&--4zu3{
		padding-bottom: 75%;
	}
}

.iframe-mitgliederverzeichnis{
	margin-bottom: $base-margin*3;
}

iframe{
	border: 0;
	padding: 0;
	margin: 0;
}