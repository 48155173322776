.module-article-bibliothek {
	@include make-row();
	margin-bottom: $base-margin*3;

	&__inner {
		@include setBasicModuleWidth-Full();
		
		&__content{
			@include make-row();
			&__inner{
				@include setBasicModuleWidth-Medium();
		}
		}
		
	}
}



.bibliothek-list{

	@include reset-list();
	
	&__item{
		margin-bottom: $base-margin;
		
	}	
}


.memberarea #dropdownMenu_category{
	display: none;
}