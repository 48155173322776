.module-article-anzeigenwarnungen{
	
	@include make-row();
	
	margin-bottom: $base-margin*3;
	
	&__inner{
		
		@include setBasicModuleWidth-Medium();
		
		.member-list-search{
			background-color: $grey-3;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			padding: $base-padding;
			margin-bottom: $base-margin*2;
			
			&>div{
				margin-bottom: 0;
			}
			
			.form-group{
				display: flex;	
				&>input[type='text']{
				margin-right: $base-margin;
				}
			}
			
			.button-group{
				display: flex;
				&>div{
					margin-left: $base-margin;
				}
			}
			
			@include media-breakpoint-down(md) {
				display: none;	
			}
		}
		

		
	}	
}




