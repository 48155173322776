.module-article-tabs{
	@include make-row();
	margin-bottom: $base-margin*2;

	&__inner {
		@include setBasicModuleWidth-Full();
		
	}

	.module-text{
		margin-bottom: 0;
		
		h2,h3,h4{
			font-family: $base-font-family-light;
			@include font-size(2.8);
		}
		
	}
}

.module-article-menu__wrapper{
	
	@include make-row();
	background-color: $grey-3;
	
	&__inner{
		
		@include setBasicModuleWidth-Medium();
		
		display: flex;
		flex: 1;

		flex-direction: column;

		.nav-tabs{
			border-bottom:0;
			
			.nav-link{
				border:0;	
				border-right: 1px solid $grey-2;
				border-radius: 0;
			}
			
			.nav-item{
				margin-bottom:0;
			}
		}
		
		
		.nav {
			background-color: $grey-3;
			display: flex;
			align-items:flex-start;

			a {
				display: block;
				padding: $base-padding/2 $base-padding $base-padding/2 $base-padding;
				text-transform: uppercase;
				font-family: $base-font-family;
				@include font-size(1.6);
				color: $base-font-color;
				//@include set-topic-color(color);

				&.active {
					background-color: $primary-color-4;
					@include set-topic-color(background-color);
					color: $white !important;
					border-right: 1px solid $grey-2;
				}

				span {
					display: block;
					height: 100%;
					padding: $base-padding/2 $base-padding;
					margin-top: 5px;
					background-color: $grey-4;
				}
			}
		}

	}
}

.module-article-content__wrapper{
	
	@include make-row();
	margin-top:$base-margin;
	
	&__inner{
	@include setBasicModuleWidth-Medium();

		.tab-content {
			
		}


	}
}