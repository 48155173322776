/*
*	enthält Variablen und Hilfsklassen, auf die die basic-Styles zurückgreifen. Wir können diese hier überschreiben.
*/

/****************
	 Typography
****************/

$base-font-family: 'acidgroteskregular';
$base-font-family-bold: 'acidgroteskbook';
$base-font-family-light: 'acidgrotesklight';

$heading-font-family: 'acidgroteskbook';
$heading-font-family-style-1: 'euclidregular';
$heading-font-family-style-2: 'playfairdisplayregular';
$heading-font-family-style-2-bold: 'playfairdisplaybold';

$fontawesome: 'Font Awesome 6 Pro';

// Font Sizes
$base-font-size: 	1.7;
$h1-font-size: 		4.6;
$h2-font-size: 		4.0;
$h3-font-size: 		3.2;
$h4-font-size: 		3.0;
$h5-font-size: 		2.4;
$h6-font-size: 		2.1;

$caption-font-size: 1.3;

$header-height: 120px;

$teaser-quadrat: 145px;
$teaser-quadrat-md: 200px;
$teaser-quadrat-md-height: 360px;
$teaser-quadrat-xs-height: 330px;

$teaser-line-height: 27px;
$teaser-line-height-md: 23px;
$teaser-line-height-xs: 20px;


// Line height
$base-line-height: 2.6;
$heading-line-height: 1.3;

// Other Sizes
$base-border-radius: 5px;
$base-spacing: $base-line-height * 1rem;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;
$base-padding: $grid-gutter-width;
$base-margin: $grid-gutter-width;

$base-module-margin: $base-spacing;




/****************
	Font Colors
****************/

/* Standardfarben */

$black:				#000;
$white:				#fff;

.black{color:$black;}
.white{color:$white;}
.bg-black{background-color:$black;}
.bg-white{background-color:$white;}

/*Hauptfarben*/

$primary-color-1:	#FFBF1B;
$primary-color-2:	#F9FD26;
$primary-color-3:	#762E66;
$primary-color-4:	#95FC6B;
$primary-color-5:	transparent;

$medium-gray: 		#000029;
$light-gray: 		#D7D5BF;
$grey-1: #D7D5BF;
$grey-2: #DFE3E5;
$grey-3: #F0F0F0;
$grey-4: #F9F9F9;

$banner-color-1: 	#0884C8;

.primary-color-1{color:$primary-color-1;}
.primary-color-2{color:$primary-color-2;}
.primary-color-3{color:$primary-color-3;}
.primary-color-4{color:$primary-color-4;}
.primary-color-5{color:$primary-color-5;}

.banner-color-1{color:$banner-color-1;}

.bg-primary-color-1{background-color:$primary-color-1;}
.bg-primary-color-2{background-color:$primary-color-2;}
.bg-primary-color-3{background-color:$primary-color-3;}
.bg-primary-color-4{background-color:$primary-color-4;}
.bg-primary-color-5{background-color:$primary-color-5;}

.bg-banner-color-1{background-color:$banner-color-1;}

.bg-grey-1{background-color:$grey-1;}
.bg-grey-2{background-color:$grey-2;}
.bg-grey-3{background-color:$grey-3;}
.bg-grey-4{background-color:$grey-4;}

/* Sekundärfarben */

//$secondary-color-1:	#3D9DB8;
$secondary-color-1:		#2288FF;
//$secondary-color-2:	#A0B2CE;
$secondary-color-2:		#01CCC5;
//$secondary-color-3:	#875920;
//$secondary-color-3:	#73002a;
$secondary-color-3:		#BE0000;
//$secondary-color-4:	#A65C82;
$secondary-color-4:		#E00074;
//$secondary-color-5:	#E5AC53;
//$secondary-color-5:	#866DA0;
$secondary-color-5:		#8632DE;
//$secondary-color-6:	#A09363;
$secondary-color-6:		#C4B372;
//$secondary-color-7:	#E5AC53;
$secondary-color-7:		#F8C51B;
//$secondary-color-8:	#2C529C;
$secondary-color-8:		#164BC1;
//$secondary-color-9:	#7C9291;
$secondary-color-9:		#00E600;

// neu
$secondary-color-10:	#15D2FF;
$secondary-color-11:	#F28E18;
$secondary-color-12:	#C97A45;

//BDZV Plus
$secondary-color-13:	#FF5050;
$secondary-color-13:	#5bae87;
$secondary-color-13:	#0000ff;


.secondary-color-1{color:$secondary-color-1;}
.secondary-color-2{color:$secondary-color-2;}
.secondary-color-3{color:$secondary-color-3;}
.secondary-color-4{color:$secondary-color-4;}
.secondary-color-5{color:$secondary-color-5;}
.secondary-color-6{color:$secondary-color-6;}
.secondary-color-7{color:$secondary-color-7;}
.secondary-color-8{color:$secondary-color-8;}
.secondary-color-9{color:$secondary-color-9;}
.secondary-color-10{color:$secondary-color-10;}
.secondary-color-11{color:$secondary-color-11;}
.secondary-color-12{color:$secondary-color-12;}
.secondary-color-13{color:$secondary-color-13;}


.bg-secondary-color-1{background-color:$secondary-color-1;}
.bg-secondary-color-2{background-color:$secondary-color-2;}
.bg-secondary-color-3{background-color:$secondary-color-3;}
.bg-secondary-color-4{background-color:$secondary-color-4;}
.bg-secondary-color-5{background-color:$secondary-color-5;}
.bg-secondary-color-6{background-color:$secondary-color-6;}
.bg-secondary-color-7{background-color:$secondary-color-7;}
.bg-secondary-color-8{background-color:$secondary-color-8;}
.bg-secondary-color-9{background-color:$secondary-color-9;}
.bg-secondary-color-10{background-color:$secondary-color-10;}
.bg-secondary-color-11{background-color:$secondary-color-11;}
.bg-secondary-color-12{background-color:$secondary-color-12;}
.bg-secondary-color-13{background-color:$secondary-color-13;}

.border-secondary-color-1{border-color: $secondary-color-1;}
.border-secondary-color-2{border-color: $secondary-color-2;}
.border-secondary-color-3{border-color: $secondary-color-3;}
.border-secondary-color-4{border-color: $secondary-color-4;}
.border-secondary-color-5{border-color: $secondary-color-5;}
.border-secondary-color-6{border-color: $secondary-color-6;}
.border-secondary-color-7{border-color: $secondary-color-7;}
.border-secondary-color-8{border-color: $secondary-color-8;}
.border-secondary-color-9{border-color: $secondary-color-9;}
.border-secondary-color-10{border-color: $secondary-color-10;}
.border-secondary-color-11{border-color: $secondary-color-11;}
.border-secondary-color-12{border-color: $secondary-color-12;}
.border-secondary-color-13{border-color: $secondary-color-13;}


/* Textfarben */

$base-font-color:		$black;
$base-link-color:		$black;
$base-action-color:		$primary-color-1;

.text-color{color:$base-font-color;}
.link-color{color:$base-link-color;}
.action-color{color:$base-action-color;}

.bg_base-font-color{background-color:$base-font-color;}
.bg_base-link-color{background-color:$base-link-color;}
.bg_base-action-color{background-color:$base-action-color;}

/*

.bg-primary-color-1 {
	color: $white;
	p.button a,
	a.button {
		&:hover,
		&:focus {
			background-color: shade($primary-color-1, 10%);
		}
	}
}
.bg-primary-color-2{
	p.button a,
	a.button {
		color: $base-font-color;
		&:hover,
		&:focus {
			background-color: shade($primary-color-2, 10%);
			color: $base-font-color;
		}
	}
}
.bg-primary-color-3{
	color: $white;
	p.button a,
	a.button {
		&:hover,
		&:focus {
			background-color: shade($primary-color-3, 10%);
		}
	}
}

*/

/* Border Colors */

$base-border-color: $light-gray;
$base-border: 1px solid $base-border-color;
$base-border-width: 4px;

/* Background Colors */

$base-background-color: #fff;
$secondary-background-color: tint($base-border-color, 75%);

/*Social Media Farben */

$social-media-color-twitter: #55acee;
$social-media-color-instagram: #004879;
$social-media-color-facebook: #45619d;
$social-media-color-google: #eb3a3c;


/****************
	Image Sizes, gerechnet vom 12er Grid
****************/

$image-width: 5;
$image-width-tablet: 5;
$image-width-mobile: 12;

$content-shift: 1;


/****************
 Forms
****************/

$form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($base-action-color, $lightness: -5%, $alpha: -0.3);


/****************
 Animations
****************/

$base-duration: 150ms;
$base-timing: ease;
