#landscapeModeHint {	
	position:fixed;
	width:100vw;
	height:100vh;
	left:0;
	top:0;
	text-align: center;
	color:$white;
	line-height: 100vh;
	background:$secondary-color-5;
	z-index:2500;
	opacity: 0.9;
	display: none;
}