.module-article-themen-teaser-main{
	
	@include make-row();
	margin-bottom: $base-margin*3;
		
	&__inner{
		
		@include setBasicModuleWidth-Full();
		
		.themen-teaser-description{
			@include make-row();
			&__inner{
				@include setBasicModuleWidth-Medium();				
			}
		}
		.themen-teaser-items{
			@include make-row();
			&__inner{
				@include setBasicModuleWidth-Medium();				
			}
		}
	}
}

.module-article-themen-teaser-sub{
	
	@include make-row();
	margin-bottom: $base-margin;
		
	&__inner{
		
		@include setBasicModuleWidth-Full();
		
		.themen-teaser-description{
			@include make-row();
			&__inner{
				@include setBasicModuleWidth-Medium();				
			}
		}
		.themen-teaser-items{
			@include make-row();
			&__inner{
				@include setBasicModuleWidth-Full();				
			}
		}
	}
}