.module-article-intro {
	@include make-row();
	margin-bottom: $base-margin*3;
	background-color: $grey-3;
	padding-bottom: $base-padding*2;
	position: relative;
	margin-top: $base-margin*3.2;
	
	&::before{
		content: '';
		width: 50px;
		height: 85px;
		background-color: $primary-color-1;
		@include set-topic-color(background-color);
		position: absolute;
		left: 0;
		top:-$base-margin*1.5;
		border-top-right-radius: $base-border-radius;
		border-bottom-right-radius: $base-border-radius;
	}
	

	&__inner {
		@include setBasicModuleWidth-Medium();

		//background-color: orange;
		
		.content-row{
			@include make-row();
			margin-top: -$base-margin*1.7;
			
			&__left{
				@include make-col-ready();
				@include make-col(7);
				
				@include media-breakpoint-down(md) {
					@include make-col(10);
					@include make-col-offset(1);
				}
				
				@include media-breakpoint-down(sm) {
					@include make-col(10);
					@include make-col-offset(1.5);
				}
		
				

				h2,h3,h4{
					font-family: $heading-font-family-style-2;
					@include font-size($h1-font-size,5.4);
				}
				
				
			}
			&__right{
				@include make-col-ready();
				@include make-col(4);
				 @include make-col-offset(1);
				 
				.image-placeholder{
					border: 4px solid $grey-4;
				}
				
				figure.figure{
					max-width: 400px;
				}
				 
				@include media-breakpoint-down(md) {
					@include make-col(11);
					@include make-col-offset(1);
				}
				@include media-breakpoint-down(sm) {
					@include make-col(10);
					@include make-col-offset(1.5);
				}
			}
			
		}
	}
}

@include media-breakpoint-down(md) {
		
}

@include media-breakpoint-down(sm) {

}
