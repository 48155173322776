.ce-textpic {

	//margin-bottom: $base-margin*3;

	display:flex;

	&.ce-left{
		flex-direction: row;
		
		.ce-bodytext{
			padding-left: $base-padding;
		}
	}
	
	&.ce-textpic-50{
		.ce-gallery,
		.ce-bodytext{
			width: 50%;
		}
	}
	
	&.ce-textpic-33{
		.ce-gallery{
			width: 33%;
		}
		.ce-bodytext{
			width: 67%;
		}
	}
	
	&.ce-textpic-25{
		.ce-gallery{
			width: 25%;
		}
		.ce-bodytext{
			width: 75%;
		}
	}
	
	
	&.ce-right{
		flex-direction: row-reverse;
		
		.ce-bodytext{
			padding-right: $base-padding;
		}
	}

	.ce-gallery{
		.image-placeholder{
			border: 4px solid $grey-2;
		}
		
		figure.figure{
			//max-width: 400px;
		}
	}


}