.teaser-list{
	@include reset-list();
	@include make-row();

	&__item{
		@include make-col-ready();
		@include make-col(4);
		margin-top: $base-margin/2;
		margin-bottom:$base-margin/2;
	}
	
	&--4er-teaser{

		&__item{
			@include make-col-ready();
			@include make-col(3);	
			margin-top: $base-margin/2;
			margin-bottom:$base-margin/2;
		}
	}
	
	&--5er-teaser{

		&__item{
			@include make-col-ready();
			@include make-col(2.4);	
			margin-top: $base-margin/2;
			margin-bottom:$base-margin/2;
		}
	}
	
	&--6er-teaser{

		&__item{
			@include make-col-ready();
			@include make-col(2);	
			margin-top: $base-margin/2;
			margin-bottom:$base-margin/2;
		}
	}
	
	&--7er-teaser{

		&__item{
			@include make-col-ready();
			@include make-col(1.700);	
			margin-top: $base-margin/2;
			margin-bottom:$base-margin/2;
		}
	}	
}

@include media-breakpoint-down(lg) {
	.teaser-list{

		&--5er-teaser{
			&__item{
				@include make-col(3);	
			}
		}
		
		&--6er-teaser{
			&__item{
				@include make-col(2.4);	
			}
		}
		&--7er-teaser{
			&__item{
				@include make-col(2);	
			}
		}
	}
}

@include media-breakpoint-down(md) {
	.teaser-list{
		&__item{
			@include make-col(6);
		}
		&--4er-teaser{
			&__item{
				@include make-col(6);	
			}
		}
		&--5er-teaser{
			&__item{
				@include make-col(4);	
			}
		}
		&--6er-teaser{
			&__item{
				@include make-col(4);	
			}
		}
		&--7er-teaser{
			&__item{
				@include make-col(3);	
			}
		}
	}
}	

@include media-breakpoint-down(sm) {
	.teaser-list{
		&__item{
			@include make-col(12);
		}
		&--4er-teaser{
			&__item{
				@include make-col(12);	
			}
		}
		&--5er-teaser{
			&__item{
				@include make-col(6);	
			}
		}
		&--6er-teaser{
			&__item{
				@include make-col(6);	
			}
		}
		&--7er-teaser{
			&__item{
				@include make-col(6);	
			}
		}
	}
}	