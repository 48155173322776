.module-cta{
	
	@include make-row();

	margin-bottom: $base-margin*2;
	margin-top: $base-margin*1.5;
	
	&__inner{
		margin-bottom: $base-margin*1.5;
		@include setBasicModuleWidth-Full();
		
		.cta-banner__wrapper{
			
			&__inner{
				
				.row{
					//background: $primary-color-1 url('../Images/Backgrounds/banner_blue.jpg') center center no-repeat;
					background-size: cover; 
					background: $banner-color-1;
					display: flex;
					justify-content: center;
					align-items: center;
					padding: $base-padding*1.5;
					flex-direction: column;
					color: $white;
					
					p{
						max-width: 50%;
						text-align:center;
						.content-wrapper-two-columns & {
							max-width: 90%;
						}
					}
					
					p:last-child(){
							margin-bottom: 0;
					}
					
					h3{
						display: flex;
						flex-direction: column;
						align-items: center;
						text-align: center;
						&::after{
							content: '';
							background-color: $white;
							height: 4px;
							width:50px;
							margin-top: $base-margin*1.5;
							margin-bottom:-$base-margin/2;
						}
					}
					
					.icon{
						position: absolute;
						top:50%;
						margin-top: -65px;
						left:10%;
						display: flex;
						justify-content: center;
						align-items: center;
						
						@include set-item-color(background-color);

						img{
							max-width: 45%;
							max-height: 45%;
						}
						
						.content-wrapper-two-columns & {
							position: relative;
							margin-top: 0;
							left: auto;
							width: 100px;
							height: 100px;
						}
					}	
				}
			}
		}
		
		
		
		.cta-button__wrapper{
			
			&__link{
				
				.row{
					background-color: $grey-2;
					@include set-item-color(background-color,0.3);
					
					p{
						display: flex;
						align-items: center;
						justify-content: space-between;
						margin-bottom: 0;
						@include make-col-ready();
						@include make-col(7.5);
						@include make-col-offset(3);
						text-transform: uppercase;
						@include font-size(1.5);
						font-family: $base-font-family-bold;
						
						.button--cta{
							margin-left: $base-margin*2;	
							@include font-size(2.7);
							transition: all $base-duration $base-timing;
							color:$white;
							@include set-item-color(background-color);

							&:hover{
								@include set-item-color(background-color,0.7);
								color: $base-font-color;
							}
							
							&.item--topic--primary-color-1,
							&.item--topic--primary-color-2{

								&:hover{
									color:$white;
								}
							}

						}
						
						&::before{
							content: '';
							width:1px;
							height:50px;
							background-color: $base-font-color;
							position: absolute;
							left:-$base-margin/2;		
						}
					}	
				}
			}
			
			&__description{
				
				.row{
					background-color: $white;
				
					p{
						
						margin-bottom: 0;
						padding-top: $base-padding/2;
						padding-bottom: $base-padding/2;
						@include make-col-ready();
						@include make-col(4);
						@include make-col-offset(3);
						@include font-size(1.6);
						font-family: $base-font-family-light;
					}
				}
			}
		}
		
		.content-wrapper-two-columns & {
			@include setBasicModuleWidth-Full();
			
			.cta-button__wrapper{
			
				&__link{

					.row{

						p{
							@include make-col(10);
							@include make-col-offset(1);
						
						}	
					}
				}
				&__description{
				
					.row{
					
						p{
							
							@include make-col(10);
							@include make-col-offset(1);
						}
					}
				}
			}
		}
		
		@include media-breakpoint-down(xl) {
		
		}

		@include media-breakpoint-down(lg) {
			
			.cta-button__wrapper{
				&__link{
					.row{
						p{
							@include make-col(8.5);
							@include make-col-offset(2);
						}	
					}
				}
				&__description{
					.row{
						p{
							@include make-col(5);
							@include make-col-offset(2);
						}
					}
				}
			}
			
			.content-wrapper-two-columns & {
				.cta-button__wrapper{
					&__link{
						.row{
							p{
								@include make-col(11);
								@include make-col-offset(1);
								
								.button{
									margin-left: $base-margin/2;
									width:90%;
								}
							}	
						}
					}
				}
			}
		}
	
		@include media-breakpoint-down(md) {

			.cta-banner__wrapper{
				
				&__inner{
				
					.row{
					
						p{
							max-width: 100%;

						}
						.icon{
							position: relative;
							margin-top: 0;
							left: auto;
						}
					}
				}
				
			}

			.cta-button__wrapper{
				&__link{
					.row{
						p{
							@include make-col(11);
							@include make-col-offset(1);
						}	
					}
				}
				&__description{
					.row{
						p{
							@include make-col(10);
							@include make-col-offset(1);
						}
					}
				}
			}
			.content-wrapper-two-columns & {
				.cta-button__wrapper{
					&__link{
						.row{
							p{
								@include make-col(11);
								@include make-col-offset(1);
								.button{
									margin-left: $base-margin*2;
									width:auto;
								}
							}	
						}
					}
					&__description{
						.row{
							p{
								@include make-col(10);
								@include make-col-offset(1);
							}
						}
					}
				}
			}
		}	
	
		@include media-breakpoint-down(sm) {
			.cta-button__wrapper{
				
				&__link{
					.row{
						p{
							@include make-col(12);
							@include make-col-offset(0);
							flex-direction: column;
							align-content: center;
							margin-top: $base-padding*2;
							
							.button{
								margin-top: $base-margin;
								margin-bottom: $base-margin;
								margin-left: 0;
							}
							
							&::before{
								content: '';
								width:50px;
								height:1px;
								background-color: $base-font-color;
								position: absolute;
								left:50%;
								margin-left:-25px;
								top:-$base-margin/1.3;		
							}
						}	
					}
				}
				&__description{
					.row{
						p{
							@include make-col(12);
							@include make-col-offset(0);
						}
					}
				}
			}
			.content-wrapper-two-columns & {
				.cta-button__wrapper{
				
					&__link{
						.row{
							p{
								@include make-col(12);
								@include make-col-offset(0);
								.button{
									margin-left: 0;
								}
							}
						}
					}
					&__description{
						.row{
							p{
								@include make-col(12);
								@include make-col-offset(0);
							}
						}
				}
				}				
			}

		}
	}
}