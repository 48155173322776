.vcard-profil{
	
	max-width: 200px;
	
	&__inner{

		.vcard{
			
			figure{
				padding: $base-padding;
				
				figcaption{
					display: none;
				}
				
			}
			
			&__name{
				font-family:$base-font-family-bold;
				text-align: center;
				padding: $base-padding/2;
				@include font-size(1.7);
			}
			
			&__position{
				background-color: $grey-3;
				border-radius: $base-border-radius;
				text-align: center;
				
				@include font-size(1.5);
				word-wrap: break-word;
				
				span{
					padding: $base-padding/4;
					display: block;
					border-radius: $base-border-radius;
				}
				
				a{
					padding: $base-padding/4;
					display: block;
					border-radius: $base-border-radius;
					color: $base-font-color;
					&:hover{
						background-color: $base-action-color;
						color:$white;
					}
				}
			}
			
			&__org{
				text-align: center;
				color:$grey-1;
				padding: $base-padding/4;
				@include font-size(1.5);
				word-wrap: break-word;
				word-break: break-word;

			}
		}
		
	}	
}




