.module-article-linklist{
	
	@include make-row();
	
	margin-bottom: $base-margin*3;
	
	&__inner{
		
		@include setBasicModuleWidth-Medium();
		
		.content-wrapper-two-columns & {
			@include setBasicModuleWidth-Full();
		}
		
		.linklist{
			@include reset-list();
			
		
			&__item{
				
				p{
					margin-bottom: 0;
				}
				
				display: flex;
				flex-direction: row;
				
				border-bottom: 2px solid $grey-3;
				padding: $base-padding;
				
				&:first-of-type {
				  border-top: 5px solid $grey-3;
				}
				
				
				&__basic{
					flex: 1 50%;
					padding-right: $base-padding;
					
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					
					.title{
						@include font-size(2.1);
						font-family: $base-font-family-light;
						margin-bottom: $base-margin;
					}
					
					.link-info{
						display: flex;
						justify-content: space-between;
						align-items: center;
						
						
						
						
						span:nth-of-type(2){
							@include font-size(1.5);
							color: $grey-1;
							//align-self: flex-end;
						}
					}
				}
				
				&__description{
					flex: 1 50%;
					padding-right: $base-padding;
					padding-left: $base-padding*2;
					border-left:2px solid $grey-2;
					@include font-size(1.5);
				}
				
				@include media-breakpoint-down(sm) {
					flex-direction: column;
					
					padding-left: 0;
					padding-right: 0;
					
					&__basic{
						padding-bottom: $base-padding;
						
						.title{
							margin-bottom: $base-margin;
						}
						
					}
					
					&__description{
						border-left: 0;
						border-top: 2px solid $grey-3;
						padding-left: 0;	
						padding-top:$base-padding;
					}
					
					.content-wrapper-two-columns & {
						//flex-direction: column;
					}
				}
			}
		}
	}	
}






