.module-article-einzelbild,
.module-article-video {
	@include make-row();
	margin-bottom: $base-margin*3;
	
	&__inner {
		@include setBasicModuleWidth-Full();

		.video-container,
		.bild-container {
			@include make-row();
			&__inner {
				@include setBasicModuleWidth-Slim();
				z-index:20;
				
				.image-placeholder{
					border: 4px solid $grey-3;
				}
				
				.content-wrapper-two-columns & {
					@include setBasicModuleWidth-Medium();
				}
				
			}
		}
		
		margin-bottom:-350px;
		.behind-text{
			display: block;
			background-color: $grey-3;
			position: relative;
			height: 350px;
			margin-left: -$base-margin/2;
			margin-right:-$base-margin/2;
			top: -50%;
			z-index: 10;
		}
		
		.content-wrapper-two-columns & {
			margin-bottom:-200px;
			.behind-text{
				height: 200px;
			}
		}
		
		
		@include media-breakpoint-down(lg) {
			margin-bottom:-250px;
			.behind-text{
				height: 250px;
			}
			.content-wrapper-two-columns & {
				margin-bottom:-130px;
				.behind-text{
					height: 130px;
				}
			}
		}
		
		@include media-breakpoint-down(md) {
			margin-bottom:-250px;
			.behind-text{
				height: 250px;
			}
			.content-wrapper-two-columns & {
				margin-bottom:-250px;
				.behind-text{
					height: 250px;
				}
			}
		}
		
		@include media-breakpoint-down(sm) {
			margin-bottom:-150px;
			.behind-text{
				height: 150px;
			}
			.content-wrapper-two-columns & {
				margin-bottom:-150px;
				.behind-text{
					height: 150px;
				}
			}
		}	
	}
}