.breadcrump-navi-wrapper {
	@include make-row();
	margin-bottom: $base-margin;	
	margin-top: $base-margin;
	
	.breadcrump-navi{
		@include reset-list();
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		&__item{
			display: flex;
			justify-content: center;
			align-items: center;
			@include font-size(1.5);
			
			&::after{
				content: '\f061';
				font-family: $fontawesome;
				font-weight: 300;
				@include font-size(1);
				margin-left: $base-margin/2;
				margin-right: $base-margin/2;
			}
			
			&--active,
			&:last-child(){
				&::after{
					content: none;	
				}
			}
			
			h1{
				@include font-size(1.5);
				margin: 0;
				padding: 0;
			}
			
			&--home{
				a{
					color: $primary-color-1;
				}
			}
		}
		@include media-breakpoint-down(sm) {
			padding: 0 $base-padding/2;	
		}
		
	}
}