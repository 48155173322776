/* skin: 'bdzv' */



.fr-info-padder{

	display: flex;
	justify-content: space-between;
	
	@include media-breakpoint-down(sm) {
		flex-direction: column;	
	}

	
	a{
		text-decoration: none;
		cursor: pointer;
		&::before{
			content: '\f019';
			font-family: $fontawesome;
			margin-right: $base-margin/4;
			font-weight: 300;
			
		}	
		
		@include media-breakpoint-down(sm) {
			margin-top: $base-margin/2;	
		}
	}
}

/* Sprite */
.fr-window-skin-bdzv .fr-side-button-icon,
.fr-window-skin-bdzv .fr-close-icon,
.fr-window-skin-bdzv .fr-thumbnails-side-button-icon,
.fr-window-skin-bdzv .fr-error-icon {
  background-image: url("../Images/Lightbox/sprite.svg");
}
/* fallback png sprite */
.fr-window-skin-bdzv.fr-no-svg .fr-side-button-icon,
.fr-window-skin-bdzv.fr-no-svg .fr-close-icon,
.fr-window-skin-bdzv.fr-no-svg .fr-thumbnails-side-button-icon,
.fr-window-skin-bdzv .fr-error-icon {
  background-image: url("../Images/Lightbox/sprite.png");
}

.fr-window-skin-bdzv .fr-error-icon {
  background-position: -160px -126px;
}

.fr-window-skin-bdzv .fr-content-background {
  background: #101010;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
}
.fr-window-skin-bdzv.fr-window-ui-fullclick .fr-content-background {
  box-shadow: none;
}

/* thumbnail shadow */
.fr-window-skin-bdzv .fr-thumbnail-wrapper {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}
.fr-window-skin-bdzv .fr-thumbnail-active .fr-thumbnail-wrapper {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
}

/* < > */
.fr-window-skin-bdzv .fr-side-button-background {
  background-color: transparent;
}
.fr-window-skin-bdzv .fr-side-previous .fr-side-button-icon {
  background-position: -13px -14px;
}
.fr-window-skin-bdzv .fr-side-next .fr-side-button-icon {
  background-position: -93px -14px;
}

.fr-window-skin-bdzv .fr-side-previous:hover .fr-side-button-icon {
  background-position: -13px -114px;
}
.fr-window-skin-bdzv .fr-side-next:hover .fr-side-button-icon {
  background-position: -93px -114px;
}

/* transition (png) */
.fr-window-skin-bdzv.fr-no-svg
  .fr-hovering-previous
  .fr-side-previous
  .fr-side-button-icon,
.fr-window-skin-bdzv.fr-no-svg.fr-mobile-touch
  .fr-side-previous
  .fr-side-button-icon {
  background-position: -13px -114px;
}
.fr-window-skin-bdzv.fr-no-svg
  .fr-hovering-next
  .fr-side-next
  .fr-side-button-icon,
.fr-window-skin-bdzv.fr-no-svg.fr-mobile-touch
  .fr-side-next
  .fr-side-button-icon {
  background-position: -93px -114px;
}

/* disabled state (png) */
.fr-window-skin-bdzv.fr-no-svg
  .fr-side-previous.fr-side-disabled
  .fr-side-button-icon,
.fr-window-skin-bdzv.fr-no-svg
  .fr-hovering-previous
  .fr-side-previous.fr-side-disabled
  .fr-side-button-icon,
.fr-window-skin-bdzv.fr-no-svg
  .fr-side-previous.fr-side-disabled:hover
  .fr-side-button-icon {
  background-position: -13px -214px;
}
.fr-window-skin-bdzv.fr-no-svg
  .fr-side-next.fr-side-disabled
  .fr-side-button-icon,
.fr-window-skin-bdzv.fr-no-svg
  .fr-hovering-next
  .fr-side-next.fr-side-disabled
  .fr-side-button-icon,
.fr-window-skin-bdzv.fr-no-svg
  .fr-side-next.fr-side-disabled:hover
  .fr-side-button-icon {
  background-position: -93px -214px;
}

/* transition (svg) */
.fr-window-skin-bdzv.fr-svg .fr-side-previous .fr-side-button-icon {
  background-position: -13px -114px;
}
.fr-window-skin-bdzv.fr-svg .fr-side-next .fr-side-button-icon {
  background-position: -93px -114px;
}
.fr-window-skin-bdzv.fr-svg .fr-side-button-icon {
  opacity: 0.5;
}

.fr-window-skin-bdzv.fr-svg .fr-side:hover .fr-side-button-icon,
.fr-window-skin-bdzv.fr-svg
  .fr-hovering-previous
  .fr-side-previous
  .fr-side-button-icon,
.fr-window-skin-bdzv.fr-svg
  .fr-hovering-next
  .fr-side-next
  .fr-side-button-icon {
  opacity: 1;
}

.fr-window-skin-bdzv.fr-svg.fr-mobile-touch .fr-side .fr-side-button-icon {
  opacity: 0.8;
} /* touch always active but at lower opacity */

/* disabled (svg) */
.fr-window-skin-bdzv.fr-svg .fr-side-disabled .fr-side-button-icon,
.fr-window-skin-bdzv.fr-svg
  .fr-hovering-previous
  .fr-side-disabled
  .fr-side-button-icon,
.fr-window-skin-bdzv.fr-svg
  .fr-hovering-next
  .fr-side-disabled
  .fr-side-button-icon,
.fr-window-skin-bdzv.fr-svg .fr-side-disabled:hover .fr-side-button-icon,
.fr-window-skin-bdzv.fr-svg.fr-mobile-touch
  .fr-side-disabled
  .fr-side-button-icon {
  opacity: 0.2;
}

/* hide for ui:inside/fullclick, only for the image type */
.fr-window-skin-bdzv.fr-window-ui-inside
  .fr-type-image
  .fr-side-disabled
  .fr-side-button-icon,
.fr-window-skin-bdzv.fr-window-ui-fullclick.fr-showing-type-image
  .fr-side-disabled
  .fr-side-button-icon {
  background-image: none;
}

/* < > responsive */
@media all and (max-width: 500px) and (orientation: portrait),
  all and (orientation: landscape) and (max-height: 414px) {
  .fr-window-skin-bdzv .fr-side-previous .fr-side-button-icon {
    background-position: 0px -300px;
  }
  .fr-window-skin-bdzv .fr-side-next .fr-side-button-icon {
    background-position: -48px -300px;
  }

  .fr-window-skin-bdzv .fr-side-previous:hover .fr-side-button-icon {
    background-position: 0px -360px;
  }
  .fr-window-skin-bdzv .fr-side-next:hover .fr-side-button-icon {
    background-position: -48px -360px;
  }

  /* transition (png) */
  .fr-window-skin-bdzv.fr-no-svg
    .fr-hovering-previous
    .fr-side-previous
    .fr-side-button-icon,
  .fr-window-skin-bdzv.fr-no-svg.fr-mobile-touch
    .fr-side-previous
    .fr-side-button-icon {
    background-position: 0px -360px;
  }
  .fr-window-skin-bdzv.fr-no-svg
    .fr-hovering-next
    .fr-side-next
    .fr-side-button-icon,
  .fr-window-skin-bdzv.fr-no-svg.fr-mobile-touch
    .fr-side-next
    .fr-side-button-icon {
    background-position: -48px -360px;
  }

  /* transition (svg) */
  .fr-window-skin-bdzv.fr-svg .fr-side-previous .fr-side-button-icon {
    background-position: 0px -360px;
  }
  .fr-window-skin-bdzv.fr-svg .fr-side-next .fr-side-button-icon {
    background-position: -48px -360px;
  }

  /* disabled state (png) */
  .fr-window-skin-bdzv.fr-no-svg
    .fr-side-previous.fr-side-disabled
    .fr-side-button-icon,
  .fr-window-skin-bdzv.fr-no-svg
    .fr-hovering-previous
    .fr-side-previous.fr-side-disabled
    .fr-side-button-icon,
  .fr-window-skin-bdzv.fr-no-svg
    .fr-side-previous.fr-side-disabled:hover
    .fr-side-button-icon {
    background-position: 0px -420px;
  }

  .fr-window-skin-bdzv.fr-no-svg
    .fr-side-next.fr-side-disabled
    .fr-side-button-icon,
  .fr-window-skin-bdzv.fr-no-svg
    .fr-hovering-next
    .fr-side-next.fr-side-disabled
    .fr-side-button-icon,
  .fr-window-skin-bdzv.fr-no-svg
    .fr-side-next.fr-side-disabled:hover
    .fr-side-button-icon {
    background-position: -48px -420px;
  }
}

/* X */
/* colors */
.fr-window-skin-bdzv.fr-window-ui-outside .fr-close-background {
  background-color: #363636;
}
.fr-window-skin-bdzv.fr-window-ui-outside
  .fr-close:hover
  .fr-close-background {
  background-color: #434343;
}

.fr-window-skin-bdzv.fr-window-ui-inside .fr-close-background,
.fr-window-skin-bdzv.fr-window-ui-fullclick .fr-close-background {
  background-color: #131313;
  filter: alpha(opacity=80);
  opacity: 0.8;
}
.fr-window-skin-bdzv.fr-window-ui-inside .fr-close:hover .fr-close-background,
.fr-window-skin-bdzv.fr-window-ui-fullclick
  .fr-close:hover
  .fr-close-background {
  background-color: #191919;
}

/* - image */
.fr-window-skin-bdzv .fr-close .fr-close-icon {
  background-position: -168px -8px;
}
.fr-window-skin-bdzv .fr-close:hover .fr-close-icon {
  background-position: -210px -8px;
}

/* - transition */
.fr-window-skin-bdzv.fr-svg .fr-close .fr-close-icon {
  background-position: -210px -8px;
  opacity: 0.8;
}
.fr-window-skin-bdzv .fr-close:hover .fr-close-icon {
  opacity: 1;
}
/* iOS 8.4.1 bug: when opacity changes it'll require 2 taps
   force a single opacity to fix this
*/
.fr-window-skin-bdzv.fr-svg.fr-mobile-touch .fr-close .fr-close-icon,
.fr-window-skin-bdzv.fr-mobile-touch .fr-close:hover .fr-close-icon {
  opacity: 1;
}

/* Thumbnails */
.fr-window-skin-bdzv .fr-thumbnail-wrapper {
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}
.fr-window-skin-bdzv .fr-thumbnail-wrapper {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}
.fr-window-skin-bdzv .fr-thumbnail-active .fr-thumbnail-wrapper {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
}
.fr-window-skin-bdzv .fr-thumbnail-wrapper {
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.3);
}
.fr-window-skin-bdzv .fr-thumbnail-overlay-border {
  border-width: 1px;
  border-color: rgba(255, 255, 255, 0.08); /* should remain rgba */
}
/* no inner border on active thumbnail */
.fr-window-skin-bdzv .fr-thumbnail-active .fr-thumbnail-overlay-border,
.fr-window-skin-bdzv .fr-thumbnail-active:hover .fr-thumbnail-overlay-border {
  border: 0;
}

/* Thumbnails < > */
.fr-window-skin-bdzv
  .fr-thumbnails-side-previous
  .fr-thumbnails-side-button-icon {
  background-position: -167px -49px;
}
.fr-window-skin-bdzv
  .fr-thumbnails-side-previous:hover
  .fr-thumbnails-side-button-icon {
  background-position: -209px -49px;
}
.fr-window-skin-bdzv
  .fr-thumbnails-side-next
  .fr-thumbnails-side-button-icon {
  background-position: -167px -91px;
}
.fr-window-skin-bdzv
  .fr-thumbnails-side-next:hover
  .fr-thumbnails-side-button-icon {
  background-position: -209px -91px;
}
/* vertical ^ (up/down) adjustments */
.fr-window-skin-bdzv.fr-thumbnails-vertical
  .fr-thumbnails-side-previous
  .fr-thumbnails-side-button-icon {
  background-position: -293px -49px;
}
.fr-window-skin-bdzv.fr-thumbnails-vertical
  .fr-thumbnails-side-previous:hover
  .fr-thumbnails-side-button-icon {
  background-position: -335px -49px;
}
.fr-window-skin-bdzv.fr-thumbnails-vertical
  .fr-thumbnails-side-next
  .fr-thumbnails-side-button-icon {
  background-position: -293px -91px;
}
.fr-window-skin-bdzv.fr-thumbnails-vertical
  .fr-thumbnails-side-next:hover
  .fr-thumbnails-side-button-icon {
  background-position: -335px -91px;
}

/* Thumbnails < > transition */
.fr-window-skin-bdzv.fr-svg
  .fr-thumbnails-side
  .fr-thumbnails-side-button-icon {
  -moz-transition: opacity 0.2s ease-in;
  -webkit-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in;
  opacity: 0.8;
}
.fr-window-skin-bdzv.fr-svg
  .fr-thumbnails-side-previous
  .fr-thumbnails-side-button-icon,
.fr-window-skin-bdzv.fr-svg
  .fr-thumbnails-side-previous
  .fr-thumbnails-side-button-disabled {
  background-position: -167px -49px;
}
.fr-window-skin-bdzv.fr-svg
  .fr-thumbnails-side-next
  .fr-thumbnails-side-button-icon,
.fr-window-skin-bdzv.fr-svg
  .fr-thumbnails-side-next
  .fr-thumbnails-side-button-disabled {
  background-position: -209px -91px;
}
.fr-window-skin-bdzv.fr-svg
  .fr-thumbnails-side:hover
  .fr-thumbnails-side-button-icon {
  opacity: 1;
}
/* vertical ^ (up/down) adjustments */
.fr-window-skin-bdzv.fr-svg.fr-thumbnails-vertical
  .fr-thumbnails-side-previous
  .fr-thumbnails-side-button-icon,
.fr-window-skin-bdzv.fr-svg.fr-thumbnails-vertical
  .fr-thumbnails-side-previous
  .fr-thumbnails-side-button-disabled {
  background-position: -293px -49px;
}
.fr-window-skin-bdzv.fr-svg.fr-thumbnails-vertical
  .fr-thumbnails-side-next
  .fr-thumbnails-side-button-icon,
.fr-window-skin-bdzv.fr-svg.fr-thumbnails-vertical
  .fr-thumbnails-side-next
  .fr-thumbnails-side-button-disabled {
  background-position: -335px -91px;
}

/* lower opacity on disabled states */
.fr-window-skin-bdzv.fr-svg
  .fr-thumbnails-side
  .fr-thumbnails-side-button-disabled,
.fr-window-skin-bdzv.fr-svg
  .fr-thumbnails-side:hover
  .fr-thumbnails-side-button-disabled {
  opacity: 0.5;
}

/* lower opacity IE < 9 using images */
.fr-window-skin-bdzv.fr-no-svg
  .fr-thumbnails-side-previous
  .fr-thumbnails-side-button-disabled
  .fr-thumbnails-side-button-icon,
.fr-window-skin-bdzv.fr-no-svg
  .fr-thumbnails-side-previous:hover
  .fr-thumbnails-side-button-disabled
  .fr-thumbnails-side-button-icon {
  background-position: -251px -49px;
}
.fr-window-skin-bdzv.fr-no-svg
  .fr-thumbnails-side-next
  .fr-thumbnails-side-button-disabled
  .fr-thumbnails-side-button-icon,
.fr-window-skin-bdzv.fr-no-svg
  .fr-thumbnails-side-next:hover
  .fr-thumbnails-side-button-disabled
  .fr-thumbnails-side-button-icon {
  background-position: -251px -91px;
}
.fr-window-skin-bdzv.fr-no-svg
  .fr-thumbnails-side
  .fr-thumbnails-side-button-disabled
  .fr-thumbnails-side-button-background,
.fr-window-skin-bdzv.fr-no-svg
  .fr-thumbnails-side:hover
  .fr-thumbnails-side-button-disabled
  .fr-thumbnails-side-button-background {
  filter: alpha(opacity=50);
}
/* vertical ^ (up/down) adjustments */
.fr-window-skin-bdzv.fr-no-svg.fr-thumbnails-vertical
  .fr-thumbnails-side-previous
  .fr-thumbnails-side-button-disabled
  .fr-thumbnails-side-button-icon,
.fr-window-skin-bdzv.fr-no-svg.fr-thumbnails-vertical
  .fr-thumbnails-side-previous:hover
  .fr-thumbnails-side-button-disabled
  .fr-thumbnails-side-button-icon {
  background-position: -377px -49px;
}
.fr-window-skin-bdzv.fr-no-svg.fr-thumbnails-vertical
  .fr-thumbnails-side-next
  .fr-thumbnails-side-button-disabled
  .fr-thumbnails-side-button-icon,
.fr-window-skin-bdzv.fr-no-svg.fr-thumbnails-vertical
  .fr-thumbnails-side-next:hover
  .fr-thumbnails-side-button-disabled
  .fr-thumbnails-side-button-icon {
  background-position: -377px -91px;
}