.page-footer {

	.module-wrapper{
		&__inner{
		//	padding-left: 0;
		//	padding-right: 0;
		}
		
	}


	h6 {
		@include font-size(1.3);
		text-transform: uppercase;
		font-family: $base-font-family-bold;
		margin-bottom: $base-margin/2;

		button {
				text-transform: uppercase;
		}
	}

	.button--round[href^="#"] {
		position: fixed;
		opacity: 0;
		right: $base-margin*2;
		bottom: $base-margin*2;
		z-index: -1;
		box-shadow: $grey-1 0 0 8px;
		background-color: $white;
		transition: all $base-duration*2 $base-timing;

		&.visible {
			opacity: 1;
			z-index: 99;

			&:hover {
				border: 1px solid $white;
				background-color: $grey-1;
				opacity: 1;
				transform: translateY(-$base-margin/2);
			}
		}
		span{
			@include font-size(4);
			font-family: $base-font-family-light;
			transform: rotate(180deg);
			margin-top: 5px;
		}
	}
}



.page-footer {
	
	&__wrapper {


			&__inner {
			@include make-container();
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			align-items: center;
			
			padding-top: $base-padding*2;
			padding-bottom: $base-padding*4;
			
			@include media-breakpoint-down(md) {
				justify-content: center;
			}
			

			&__meta-wrapper {
				

				.meta-navi {
					@include reset-list();
					display: flex;
					flex-direction: row;
					align-items: center;
					
					@include media-breakpoint-down(md) {
						flex-direction: column;
						justify-content: center;
						align-items: center;	
					}
					
					&__item {
						display: flex;
						align-items: center;
						padding-left: $base-padding*3;
						@include font-size(1.5);
						
						@include media-breakpoint-down(md) {
							margin-bottom: $base-margin;
							padding-left: 0;
						}
						
						/*&:not(:first-child){
							&::before{
								content: '';
								height: 40px;
								width:1px;
								background-color: $grey-1;
								margin-right: $base-margin*1.5;
							}
						}
						*/		
					}
				}	
			}
		}
	}
}







