/*******
*	main.scss
********/

/*******
*	Use Bootstrap as Grid-System, add and remove to your needs!!
*	https://getbootstrap.com/docs/5.2/customize/sass/
********/

$grid-columns:      12;
$grid-gutter-width: 1.5rem;
$grid-row-columns:  6;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
);


// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here

// 3. Include remainder of required Bootstrap stylesheets
@import "node_modules/bootstrap/scss/variables";

// 4. Include any default map overrides here

// 5. Include remainder of required parts
@import "node_modules/bootstrap/scss/maps";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/root";

// 6. Optionally include any other parts as needed
@import "node_modules/bootstrap/scss/utilities";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/images";
@import "node_modules/bootstrap/scss/containers";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/helpers";
@import "node_modules/bootstrap/scss/offcanvas";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
//@import "node_modules/bootstrap/scss/utilities/api";

// 8. Add additional custom code here



/*******
*	Define own variables & mixins
********/

// own Mixins
@import "mixins";

// own Variables
@import "variables";

// own ANimations
@import "animations";


/********
*	Plugins
**********/

// Carousel - only what we really need
@import "node_modules/owl.carousel/src/scss/owl.carousel";
@import 'node_modules/owl.carousel/src/scss/core';
@import 'node_modules/owl.carousel/src/scss/lazyload';


//Fresco
@import 'node_modules/@staaky/fresco/src/css/fresco.scss';


/*******
*	Webfonts + Font Awesome
********/

@import "fonts";

$fa-font-path:        "./Fonts/fontawesome" !default;
@import "node_modules/@fortawesome/fontawesome-pro/scss/fontawesome.scss";
@import "node_modules/@fortawesome/fontawesome-pro/scss/light.scss";
@import "node_modules/@fortawesome/fontawesome-pro/scss/regular.scss";
@import "node_modules/@fortawesome/fontawesome-pro/scss/solid.scss";
@import "node_modules/@fortawesome/fontawesome-pro/scss/brands.scss";


/*******
*	Import and override basic styles for your own need (fonts, buttons, texts etc...)
********/

@import "Basics/typography";
@import "Basics/buttons";
@import "Basics/figure";
@import "Basics/forms";
@import "Basics/lists";
@import "Basics/icon";
@import "Basics/tables";


/*******
*	Hamburger Menu, look here: https://github.com/jonsuh/hamburgers
********/

// Settings
$hamburger-padding-x: 0px !default;
$hamburger-padding-y: 0px !default;
$hamburger-layer-width: 30px !default;
$hamburger-layer-height: 4px !default;
$hamburger-layer-spacing: 7px !default;
$hamburger-layer-color: $base-font-color !default;
$hamburger-layer-border-radius: 3px !default;
$hamburger-hover-opacity: .5 !default;
$hamburger-hover-transition-duration: 0.15s !default;
$hamburger-hover-transition-timing-function: linear !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity;
$hamburger-active-layer-color: $hamburger-layer-color;
// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter    : opacity(50%) !default;

// Types
$hamburger-types: (
  spin
) !default;

// Base Hamburger
//@import "node_modules/hamburgers/_sass/hamburgers/base";

// Hamburger types
//@import "node_modules/hamburgers/_sass/hamburgers/types/spin";


/*******
*	Setup the Layout
********/

@import "Layouts/layout";
@import "Layouts/header";
//@import "Layouts/offcanvas";
@import "Layouts/footer";
@import "Layouts/landingpage";


/*******
*	Setup Module
********/


@import "Modules/article__filter";
@import "Modules/article__teaser";
@import "Modules/module__article-text";
@import "Modules/module__article-infobox";
@import "Modules/module__article-intro";
@import "Modules/module__article-iframe";
@import "Modules/module__article-sammler";
@import "Modules/module__article-galerie";
@import "Modules/module__article-tabs";
@import "Modules/module__article-accordion";
@import "Modules/module__article-blockquote";
@import "Modules/module__article-share";
@import "Modules/module__article-tags";
@import "Modules/module__article-cta";
@import "Modules/module__article-video-einzelbild";
@import "Modules/module__article-zitat-position";
@import "Modules/module__article-themen-teaser";
@import "Modules/module__article-linklist";
@import "Modules/module__article-profile";
@import "Modules/module__article-subthemen";
@import "Modules/module__article-preistraeger";
@import "Modules/module__article-liste-storyteaser";
@import "Modules/module__article-stage-standard";
@import "Modules/module__article-ansprechpartner";
@import "Modules/module__article-partnerlogos";
@import "Modules/module__article-positionsslider";
@import "Modules/module__chart";
@import "Modules/module__article-login-box";
@import "Modules/module__article-calendar";
@import "Modules/module__article-directory";
@import "Modules/module__article-bibliothek";
@import "Modules/module__article-two-columns";
@import "Modules/sammler__more-topics.scss";
@import "Modules/sammler__downloads-links.scss";
@import "Modules/global__iframe";
@import "Modules/global__image-teaser";
@import "Modules/global__stage-teaser";
@import "Modules/global__teaser-list";
@import "Modules/global__article-list";
@import "Modules/global__vcard-profil";
@import "Modules/global__marginalspalte";
@import "Modules/global__breadcrumb";
@import "Modules/global__text-with-image";
@import "Modules/global__subnavi";
@import "Modules/module__article-anzeigenwarnungen";
@import "Modules/mobile";
@import "Modules/fresco-skin-bdzv";
@import "Modules/_global__jquery_ui";

