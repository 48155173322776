.module-article-blockquote{
	
	@include make-row();
	margin-bottom: $base-margin;
	
	&__inner {
		@include setBasicModuleWidth-Medium();

		.content-wrapper-two-columns & {
			@include setBasicModuleWidth-Full();
		}		

		/*
			&::before{
			content: '';
			background-color: $black;
			width:50px;
			height: 1px;
			position: absolute;
			left: -60px;
			top:14px;
		}
		*/
				
		&__cite{
			@include font-size(1.7);
			font-family: $base-font-family-bold;
			text-transform: none;
			font-style: normal;
			display: flex;
			align-items: center;
			//position: relative;
			
			&::before{
				content: '';
				background: url('../Images/Icons/ic_zitat.svg') center center no-repeat;
				background-size:contain;
				height: 30px;
				width: 30px;
				margin-right: $base-margin;
				//position: absolute;
			}
			
		}

		&__wrapper{
			font-family: $heading-font-family-style-2;
			@include font-size(3);		
			margin-top: $base-margin;	
		}
	}
}