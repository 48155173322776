/****************
 Fonts
****************/

@font-face {
    font-family: 'acidgrotesklight';
    src: url('./Fonts/acidgrotesk-light/FFF-AcidGrotesk-Light.woff2') format('woff2'),
         url('./Fonts/acidgrotesk-light/FFF-AcidGrotesk-Light.woff') format('woff'),
         url('./Fonts/acidgrotesk-light/FFF-AcidGrotesk-Light.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'acidgroteskregular';
    src: url('./Fonts/acidgrotesk-regular/FFF-AcidGrotesk-Regular.woff2') format('woff2'),
         url('./Fonts/acidgrotesk-regular/FFF-AcidGrotesk-Regular.woff') format('woff'),
         url('./Fonts/acidgrotesk-regular/FFF-AcidGrotesk-Regular.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'acidgroteskbook';
    src: url('./Fonts/acidgrotesk-book/FFF-AcidGrotesk-Book.woff2') format('woff2'),
         url('./Fonts/acidgrotesk-book/FFF-AcidGrotesk-Book.woff') format('woff'),
         url('./Fonts/acidgrotesk-book/FFF-AcidGrotesk-Book.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}