.module-article-galerie {
	@include make-row();
	margin-bottom: $base-margin*3;
	
	&__inner {
		@include setBasicModuleWidth-Full();
		

		.galerie-container{
			@include make-row();
			&__inner {
				@include setBasicModuleWidth-Slim();
				z-index:20;
				
				.content-wrapper-two-columns & {
					@include setBasicModuleWidth-Medium();
				}
				
			}
		}
		
		margin-bottom:-350px;
		.behind-text{
			display: block;
			background-color: $grey-3;
			position: relative;
			height: 350px;
			margin-left: -$base-margin/2;
			margin-right:-$base-margin/2;
			top: -50%;
			z-index: 10;
		}
		
		.content-wrapper-two-columns & {
			margin-bottom:-200px;
			.behind-text{
				height: 200px;
			}
		}
		
		
		@include media-breakpoint-down(lg) {
			margin-bottom:-250px;
			.behind-text{
				height: 250px;
			}
			.content-wrapper-two-columns & {
				margin-bottom:-130px;
				.behind-text{
					height: 130px;
				}
			}
		}
		
		@include media-breakpoint-down(md) {
			margin-bottom:-250px;
			.behind-text{
				height: 250px;
			}
			.content-wrapper-two-columns & {
				margin-bottom:-250px;
				.behind-text{
					height: 250px;
				}
			}
		}
		
		@include media-breakpoint-down(sm) {
			margin-bottom:-150px;
			.behind-text{
				height: 150px;
			}
			.content-wrapper-two-columns & {
				margin-bottom:-150px;
				.behind-text{
					height: 150px;
				}
			}
		}	
	}
	
	
	&__thumbs {
		@include setBasicModuleWidth-MediumLarge();
		margin-top: $base-margin;
		
		.galerie-thumbs__list{
			@include reset-list();
			display: flex;
			justify-content: flex-end;
			&__item{
				margin-left: $base-margin/2;
				width:100px;
				
				.content-wrapper-two-columns & {
					width: 75px;	
				}
				
				&--more-than-5{
					
					a{
						position: relative;
						&::after{
							width: 100%;
							height: 100%;
							content: '';
							position: absolute;
							top:0;
							background: linear-gradient(to left, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%)
						}
					}
				}
			}
		}
			
		@include media-breakpoint-down(sm) {
			display: none;	
		}
	}	
}