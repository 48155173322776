.module-article-liste-storyteaser {
	@include make-row();
	margin-bottom: $base-margin*3;
	
	
	&__inner {
		@include setBasicModuleWidth-Medium();

			
		@include media-breakpoint-down(lg) {
		
		}
		
		@include media-breakpoint-down(md) {
		
		}
		
		@include media-breakpoint-down(sm) {
		
		}	
	}
}