.module-article-liste-positionsslider {
	@include make-row();
	margin-bottom: $base-margin*3;
	padding-top: 150px;
	
	&__inner {
		@include setBasicModuleWidth-Full();
		background-color: $grey-3;
		padding-bottom: $base-padding*2;

			
		@include media-breakpoint-down(lg) {
		
		}
		
		@include media-breakpoint-down(md) {
		
		}
		
		@include media-breakpoint-down(sm) {
		
		}	
	}
}

.module-article-liste-positionsslider__wrapper {

	@include make-row();
	margin-top:-150px;
	
	&__inner {
		@include setBasicModuleWidth-Medium();
		padding-left: $base-padding;
		padding-right: $base-padding;
	}
}

.module-article-liste-positionsslider__morelink {
	
	@include make-row();
	&__inner {
		@include setBasicModuleWidth-Full();
		text-align: center;
		margin-top: $base-margin;
		align-items: center;
		display: flex;
		justify-content: center;

	}
}



.module-article-positionslider-singleslide {
	@include make-row();
	//margin-bottom: $base-margin*3;
	min-height: 450px;

	&__inner {
		@include setBasicModuleWidth-Full();
		display: flex;
		
		&__left{
			@include make-col-ready();
			@include make-col(6);
			color:$white;
			background-color: $primary-color-1;	
			height: 100%;
			
			@include set-topic-color(background-color);
			
			.single-slide-question{
				padding: $base-padding;
				display:flex;
				flex-direction: column;
				height: 100%;
				justify-content: space-between;
				
				p{
					margin: 0;
				}
				sub,
				.counter{
					font-family:  $heading-font-family-style-2;
					@include font-size(4.8,0);		
				}
				
				.counter{
					margin-top: $base-margin;
					margin-bottom: $base-margin;
				}
				
				sub{
					bottom:-$base-margin;
				}
				
				blockquote{
					@include font-size($h5-font-size,3);
					font-family:  $heading-font-family-style-2;
					padding-left: $base-padding*2;
					padding-top: $base-margin;
					padding-bottom: $base-margin;
				}
				
				.position{
					display: flex;
					flex-direction: column;
					padding-left: $base-padding*2;
					&::before{
						content: '';
						background-color: $white;
						width: 100px;
						height: 4px;
						margin-bottom: $base-margin/2;
					}
				}
				
			}
			
		}
		
		&__right{
			@include make-col-ready();
			@include make-col(6);	
			border-bottom:2px solid $grey-2;
			background-color: $white;
			
			.single-slide-answer{
				padding: $base-padding;
				
				h2,h3,h4{
					@include font-size($h5-font-size,$h5-font-size);
					font-family: $base-font-family-bold;
					border-bottom: 4px solid $primary-color-1;
					padding-bottom: $base-padding/2;
					margin-bottom: $base-margin*2;
					@include set-topic-border(border-bottom);
				}
			}
			
			
			
			
			
			
		}
		

			
		@include media-breakpoint-down(lg) {
			.content-wrapper-two-columns & {
				display: block;
				&__left{
					@include make-col(12);
					.counter{
						margin-bottom: $base-margin*2;
					}
				}
				&__right{
					@include make-col(12);
				}
			}
		}
		
		@include media-breakpoint-down(md) {
			.content-wrapper-two-columns & {
				display: flex;
				&__left{
					@include make-col(6);
				}
				&__right{
					@include make-col(6);
				}
			}
		}
		
		@include media-breakpoint-down(sm) {
			display: block;
			&__left{
				@include make-col(12);
				
				.single-slide-question{
					.counter{
						margin-bottom: $base-margin*2;
					}	
					blockquote{
						padding-left: 0;
					}
					.position{
						padding-left: 0;
					}
				}
			}
			&__right{
				@include make-col(12);
			}
			.content-wrapper-two-columns & {
				display: block;
				&__left{
					@include make-col(12);
					.counter{
						margin-bottom: $base-margin*2;
					}
				}
				&__right{
					@include make-col(12);
				}
			}
		}	
	}
}