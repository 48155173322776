.module-wrapper{
	&__inner{
		//allgemein
		ol,
		ul{
			

			
			li{

				&>strong{
					margin-bottom: 5px;
					display: inline-block;
				}
				ol,
				ul{
					margin-top: $base-margin/2;
					li{
						&>strong{
							margin-bottom: 5px;
							display: inline-block;
						}
					}
				}
			}
		}
		// Speziallisten		
		ul.list{
			list-style: none;
			margin-left:-$base-margin;
			
			li{
				margin-bottom: $base-margin/2;
				padding-left: $base-margin/3;
			}

			ul{
				list-style: none;
			}
			
			&.list--checkmark{
				li{
					position: relative;
					&::before{
						content:'\f00c';
						color: $base-font-color;
						font-family: $fontawesome;
						position: absolute;
						left:-$base-margin;
						top:0px;
					}
				}
			}
			
			&.list--arrow{
				li{
					position: relative;
					&::before{
						content:'\f061';
						color: $base-font-color;
						font-family: $fontawesome;
						position: absolute;
						left:-$base-margin;
						top:0px;
						font-weight: 300;
					}
				}
			}
			
			&.list--paragraph{
				li{
					position: relative;
					&::before{
						content:'§';
						color: $base-font-color;
						font-family: $heading-font-family-style-2;
						position: absolute;
						left:-$base-margin;
						top:-2px;
						@include font-size(1.8);
					}
				}
			}
			
			&.list--line{
				li{
					position: relative;
					&::before{
						content:'';
						background-color: $base-font-color;
						width:20px;
						height:2px;
						position: absolute;
						left:-$base-margin*1.3;
						top:12px;
					}
				}
			}
			
			&.list--bottom-line{
				@include reset-list();
				li{
					border-bottom: 1px solid $grey-2; 
					padding-bottom: $base-padding/2;
					position: relative;
					
					&:last-child(){
						border-bottom: 0px;
					}
				}
			}
			
			&.list--downloads{
				@include reset-list();
				margin-left: $base-margin*1.5;
				
				li{
					
					&>strong,
					&>a{
						margin-left: -$base-margin*2;	
					}
					
					&>strong>a.link,
					&>a.link{
						&::before{
							margin-right: 20px;
							margin-left: 0;
							@include font-size(2);
						}  
					}
					&>strong>a.link--download,
					&>a.link--download{
						&::before{
							margin-right: 20px;
						}  
					}
					&>strong>a.link--pdf,
					&>a.link--pdf{
						&::before{
							margin-right: 25px;
						}  
					}
				}
			}		
		}
	}
}