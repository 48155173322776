.module-article-sammler {
	@include make-row();
	margin-bottom: $base-margin*3;
	margin-top: $base-margin*2;
	background-color: $grey-3;
	
	&__inner {
		@include setBasicModuleWidth-Medium();

		.content-row{
			@include make-row();
			padding-top: $base-padding*2;
			padding-bottom: $base-padding*2;
			
			&__left{
				@include make-col-ready();
				@include make-col(5.5);
			}
			&__right{
				@include make-col-ready();
				@include make-col(5);
				@include make-col-offset(1);

				margin-top: -$base-margin*4;
				
				
			}
			
			&--row-reverse{
				.content-row__left{
					order:13;
					@include make-col-offset(1);
				}
				.content-row__right{
					order:1;
					@include make-col-offset(0);
				}	
			}
		}
		
		.button{
			margin-top: $base-margin;
			
		}
		
		date{
			text-align: right;
			display: block;
			margin-bottom: $base-margin;
			margin-top: -$base-margin;
		}
		
		h2,h3,h4{
			font-family: $heading-font-family-style-2-bold;
			@include font-size($h6-font-size);
		}
		
		.image-placeholder{
			border: 4px solid $grey-3;
		}
		
		figure.figure{
			max-width: 400px;
		}
		
		
		
	}
}


@include media-breakpoint-down(md) {
	.module-article-sammler {
		&__inner {
			.content-row{
				&__left{
					@include make-col(10);
					@include make-col-offset(1);
				}
				&__right{	
					@include make-col(7);
					@include make-col-offset(1);
					margin-top: $base-margin;
					//max-width: 450px;
				}
				&--row-reverse{
				.content-row__left{
					order:1;
					@include make-col-offset(1);
				}
				.content-row__right{
					order:13;
					@include make-col-offset(1);
				}	
			}
			}
		}
	}
}
@include media-breakpoint-down(sm) {
	.module-article-sammler {
		&__inner {
			.content-row{
				&__right{	
					@include make-col(10);
					@include make-col-offset(1);
				}
			}
		}
	}
}



