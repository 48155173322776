.module-article-tags {
	@include make-row();
	margin-bottom: $base-margin*3;
	&__inner {
		@include setBasicModuleWidth-Medium();
		
		.content-wrapper-two-columns & {
			@include setBasicModuleWidth-Full();
		}
	}
}

.tags-list{
	@include reset-list();
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;

	&__item{
		margin: 0 $base-margin/2 $base-margin/2 0;		
	}
	
}