.module-article-accordion{
	
	@include make-row();
	
	margin-bottom: $base-margin*3;
	
	&__inner{
		
		@include setBasicModuleWidth-Full();
		

		.card-items{
			@include make-row();
			&__inner{
				@include setBasicModuleWidth-Medium();
				
				.content-wrapper-two-columns & {
					@include setBasicModuleWidth-Full();
				}
			}
		}
		
		
		.card{
			border-top: 1px solid $primary-color-1;
			
			&:last-child(){
				border-bottom: 1px solid $primary-color-1;
			}
			
			.card-header{
				min-height: 80px;
				height:auto;
				display: flex;
				flex-direction: column;
				align-content: center;
				justify-content: center;
		
			}
			
			
			h3{
				margin:0;
				padding: 0;
				min-height: 80px;
				line-height: 0;
				height:auto;
				button.accordion{
					text-align: left;
					width: 100%;
					border: 0;	
					padding: 0;
					outline: 0;
					cursor: pointer;
					color:$base-action-color;
					position: relative;
					padding-right: $base-padding*3;
					padding-left: $base-padding;
					min-height: 82px;
					height:auto;
					@include font-size(2);
					font-family: $base-font-family-bold;
					background-color: $grey-3;
					
					
					
					&::after{
						content: '\f078';
						font-family: 'Font Awesome 5 Pro';
						font-weight: 300;
						@include font-size(2);
						position: absolute;
						right:$base-margin;
						top:27px;
						transition: all $base-duration $base-timing;
						transform: rotate(180deg);
					}
					
					&::before{
						content: '';
						background-color: $primary-color-1;
						width:50px;
						height: 1px;
						position: absolute;
						left:0;
						bottom: 0;
						transition: width $base-duration $base-timing;
					}
					
					&.collapsed{
						background-color: transparent;
						font-family: $base-font-family;
						
						&:hover{
							background-color: $grey-3;
						}
						
						&::before{
							width:0;
						}
						
						&::after{
							transform: rotate(0deg);
						}
					}	
				}
			}
			.card-body{
				padding: $base-padding;
			}
		}
	}	
}




