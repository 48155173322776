.module-article-profil{
	
	@include make-row();
	margin-bottom: $base-margin*1.5;
		
	&__inner{
		
		@include setBasicModuleWidth-Full();
		
		.profil-description{
			@include make-row();
			&__inner{
				@include setBasicModuleWidth-Medium();				
			}
		}
		.profil-items{
			@include make-row();
			&__inner{
				@include setBasicModuleWidth-Medium();				
			}
		}
	}
}