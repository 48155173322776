.module-article-liste-partner{
	@include make-row();
	margin-bottom: $base-margin*3;
	
	&__inner {
		@include setBasicModuleWidth-Large();

		.teaser-list--5er-teaser__item,
		.teaser-list--6er-teaser__item,
		.teaser-list--7er-teaser__item{
		
			text-align: center;
			justify-content: center;
			align-items: center;
			
			a{
				display: flex;
				height: 100%;
				align-items: center;
				justify-content: center;
			}
			
			img{
				max-height: 80px;
				max-width: 140px;
			}
			
			
		}

		.teaser-list--6er-teaser__item{	
			img{
				max-height: 80px;
				max-width: 125px;
			}
		}

		.teaser-list--7er-teaser__item{	
			img{
				max-height: 80px;
				max-width: 110px;
			}
		}

		
			
		@include media-breakpoint-down(lg) {
		
		}
		
		@include media-breakpoint-down(md) {
		
		}
		
		@include media-breakpoint-down(sm) {
		
		}	
	}
}