.module-article-subthemen{
	
	@include make-row();
	margin-bottom: $base-margin*3;
		
	&__inner{
		
		@include setBasicModuleWidth-Medium();
		
		.subthemen__list{
			@include reset-list();
			display: flex;
			flex-wrap: wrap;
			
			&__item{
				margin-right: $base-margin/2;
				margin-bottom:$base-margin/2;
			}
			
			
		}
	}
}