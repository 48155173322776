.module-text{
	
	@include make-row();
	margin-bottom: $base-margin;
	
	&__inner{
		
		@include setBasicModuleWidth-Medium();
		
		.module-article-tabs & ,
		.content-wrapper-two-columns & ,
		.page-footer & {
			@include setBasicModuleWidth-Full();
		}
		
		
		
		
		h2,h3,h4{
			//margin-bottom: 0;
		}
		
	}
	
}