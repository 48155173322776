.module-article-ansprechpartner {
	@include make-row();
	margin-bottom: $base-margin*3;
	
	&__inner {
		@include setBasicModuleWidth-Medium();
		
		.content-wrapper-two-columns & {
			@include setBasicModuleWidth-Full();
		}

			
		@include media-breakpoint-down(lg) {
		
		}
		
		@include media-breakpoint-down(md) {
		
		}
		
		@include media-breakpoint-down(sm) {
		
		}	
	}
}


.module-ansprechpartner-single{
	@include make-row();
	
	max-width: 600px;
	
	//margin-top:$base-margin/2;
	margin-bottom: $base-margin;
	
	&--reverse{
		flex-direction: row-reverse;
	}
	
	&__image{
		@include make-col-ready();
		@include make-col(5);

		.image-placeholder{
			border: 4px solid $grey-3;
		}

	}
	
	&__description{
		@include make-col-ready();
		@include make-col(7);
		@include font-size(1.5);

		display: flex;
		flex-direction: column;
		justify-content: space-between;
		
		p{
			margin-bottom: 0;
			@include font-size(1.5);
		}
		
		p.position,
		p.ort,
		p.firma{
			color: $grey-1;
			//margin-bottom: $base-margin;
		}
		
		header{
			font-family: $base-font-family-bold;
		}
		
		&__top{
			padding-top: $base-padding;
			margin-bottom: $base-margin;
			
			.content-wrapper-two-columns & {
				padding-top: $base-padding/2;	
			}
			
		}
		
		&__bottom{
			p{
				margin-top: $base-margin/4;
				
				&:first-child(){
					margin-top: 0;
				}
			}
		}
	}
}

.module-ansprechpartner-liste{
	@include reset-list();
	@include make-row();
	&__item{
		@include make-col-ready();
		@include make-col(6);
	}

	.content-wrapper-two-columns__inner__right & {
		&__item{
			@include make-col-ready();
			@include make-col(12);
		}
	}


}



.module-ansprechpartner-single-detail{

	position: relative;
	

	&::after{
		content: '';
		background-color: $base-font-color;
		width:4px;
		height: 100%;
		position: absolute;
		left:50%;
		top:0;
		margin-left: -2px;
	}
	

	&__inner{
		padding: $base-padding 0;
	
		&__left{
			@include make-col-ready();
			@include make-col(6);
			
			&__inner{

				padding: $base-padding;
				background-color: $grey-3;
				margin-right: $base-margin/2;
				
				.module-ansprechpartner-single {
					margin-bottom: 0;
					margin-top:-$base-margin*2;
					
					.image-placeholder{
						border: 4px solid $grey-4;
					}
					
					&__description{
						
						&__top{
							padding-top: $base-padding*2;
						}
						
						
					}
					
				}
			}
		}
		
		&__right{
			@include make-col-ready();
			@include make-col(6);
			&__inner{

				margin-left: $base-margin/2;
				
			}
			
			
		}		
	}
	
	.content-wrapper-two-columns__inner__right & {
	
		&::after{
			display: none;	
		}

		&__inner{
		
			&__right,
			&__left{
				@include make-col(12);
			}
			
			.module-ansprechpartner-single{
				&__description{
					&__top{
						padding-top: $base-padding;
					}
				}
			}
			
			&__right{
				border-top:4px solid $base-font-color;
				&__inner{
					margin-left: 0;
					margin-top: $base-margin;
				}
				
			}		
		}
	}
}



@include media-breakpoint-down(xl) {
	.module-ansprechpartner-single{

		
		.content-wrapper-two-columns & {
			&__image{
				@include make-col(10);
			}
			&__description{
				@include make-col(12);
				&__top{
					padding-top: $base-padding/2;	
				}
			}
		}
	}
}



@include media-breakpoint-down(lg) {
	.module-ansprechpartner-single{
		&__image{
			@include make-col(5);
		}
		&__description{
			@include make-col(7);
		}
		
		.content-wrapper-two-columns & {
			&__image{
				@include make-col(12);
			}
			&__description{
				@include make-col(12);
				&__top{
					padding-top: $base-padding/2;	
				}
			}
		}
	}
}

@include media-breakpoint-down(md) {
	.module-ansprechpartner-single{
		&__image{
			@include make-col(12);
	
		}
		&__description{
			@include make-col(12);
			margin-top: $base-margin/2;
			
			&__top{
				padding-top: $base-padding/2;	
			}
		}
	}
	
}


@include media-breakpoint-down(sm) {
	.module-ansprechpartner-liste{

		&__item{
			@include make-col(12);
		}
	}	
	
	.module-ansprechpartner-single-detail{

		&::after{
			display: none;	
		}

		&__inner{
		
			&__right,
			&__left{
				@include make-col(12);
			}
			
			.module-ansprechpartner-single{
				&__description{
					&__top{
						padding-top: $base-padding;
					}
				}
			}
			
			&__right{
				border-top:4px solid $base-font-color;
				&__inner{
					margin-left: 0;
					margin-top: $base-margin;
				}
				
			}		
		}
	}	
}