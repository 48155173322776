.image-teaser {
	
	> div,
	> a div {

		
		&.image-teaser__placeholder--black-layer,
		&.background--image{
			&::before{
				background-color: $black;
				opacity: 0.3;
			}
		}
		
		&.background-blue {
			background-color: $primary-color-1;
		}
		
		&.background-blue--16zu9{
			background: $primary-color-1 url('../Images/Backgrounds/background-blue--16zu9.jpg') center center no-repeat;
			background-size: cover;
		}
		
		&.background-blue--4zu3{
			background: $primary-color-1 url('../Images/Backgrounds/background-blue--4zu3.jpg') center center no-repeat;
			background-size: cover;
		}
		
		&.background-blue--2zu1{
			background: $primary-color-1 url('../Images/Backgrounds/background-blue--2zu1.jpg') center center no-repeat;
			background-size: cover;
		}
		
		&.background-blue--3zu4{
			background: $primary-color-1 url('../Images/Backgrounds/background-blue--3zu4.jpg') center center no-repeat;
			background-size: cover;
		}
		&.background-blue--1zu1{
			background: $primary-color-1 url('../Images/Backgrounds/background-blue--quadratisch.jpg') center center no-repeat;
			background-size: cover;
		}
		
		
	}
		
	&__placeholder {
		
		background-color: $grey-1;
		
		& > :first-child {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
		}
	
		&--2zu1,
		&--1zu1,
		&--16zu9,
		&--4zu3,
		&--3zu4 {
			position: relative;
			& > :first-child {
			    position: absolute;
			    top: 0;
			    left: 0;
			    height: 100%;
			}
		}
		&--16zu9 {
			&::before {
				content: "";
				display: block;
				padding-bottom: calc(100% / (16/9));
	  		}
		}
		&--4zu3 {
			&::before {
				content: "";
				display: block;
				padding-bottom: calc(100% / (4/3));
	  		}
		}
		&--3zu4 {
			&::before {
				content: "";
				display: block;
				padding-bottom: calc(100% / (3/4));
	  		}
		}
		&--2zu1 {
			&::before {
				content: "";
				display: block;
				padding-bottom: calc(100% / (2/1));
	  		}
		}
		&--1zu1 {
			&::before {
				content: "";
				display: block;
				padding-bottom: calc(100% / (1/1));
	  		}
		}
	}
	
	.teaser-icon{
		background: transparent url('../Images/Icons/ic_corner.svg') center center no-repeat;
		background-size: cover;
		position: absolute;
		height: 30px;
		width: 30px;
		left: $base-margin;
		top:$base-margin;
		transition: all $base-duration/2 $base-timing;
		
		&--top-right{
			right: $base-margin;
			left: auto;
			transform: rotate(-90deg);
		}
		&--bottom-right{
			right: $base-margin;
			left: auto;
			top:auto;
			bottom: $base-margin;
		}
		&--top-left{
			transform: rotate(-180deg);
		}
		
		&--bottom-left{
			top:auto;
			bottom: $base-margin;
			transform: rotate(-270deg);
		}
		&--center-center{
			top:50%;
			left:50%;
			height: 4px;
			margin-top:-2px;
			width: 50px;
			margin-left: -25px;
			background: none;
			background-color: $white;
		}
	}
	
	a{

		&:hover{
			.teaser-icon{
				&--top-right{
					right: $base-margin*1.25;
					top: $base-margin*1.25;
				}
				&--top-left{
					left: $base-margin*1.25;
					top: $base-margin*1.25;
				}
				&--bottom-right{
					right: $base-margin*1.25;
					bottom: $base-margin*1.25;
				}
				&--bottom-left{
					bottom: $base-margin*1.25;
					left: $base-margin*1.25;
				}
				&--center-center{
					width: 60px;
					margin-left: -30px;
				}
			}
		}
	}
	&__content-wrapper{
	
		color:$white;
		
		header{
			width: 100%;
			display: flex;
			flex-grow: 100%;
			flex-basis: 100%;
			flex-shrink: 50%;
			align-items: center;
			justify-content: center;
			text-align: center;
			padding-left: $base-padding;
			padding-right:$base-padding;
		}
		
		p{
			width: 100%;
			justify-content: center;
			align-items: center;
			display: flex;
			flex-grow: 100%;
			flex-basis: 100%;
			flex-shrink: 50%;
			margin: 0;
			text-align: center;
			padding-left: $base-padding*1.5;
			padding-right:$base-padding*1.5;
		}
		
		&--position{
			header{

			flex-basis: 62%;
			}
			div{
				flex-basis: 38%;
				align-items: flex-start;
				text-align: center;
				
				.icon{

					img{
						width: 100%;
						max-width: 41%;
						height: 100%;
						max-height: 41%;
					}
				}
			}
		}
		
		
		h3,h4,h5{
			font-family: $heading-font-family-style-2;
			@include font-size($h5-font-size);
			margin: 0;
		}
	}
}

@include media-breakpoint-down(sm) {
	.image-teaser{
		&__content-wrapper{
			h3,h4,h5{
				//border: 1px solid blue;
				//@include font-size(2);
			}
		}
	}
}


