.klaro.we_cookie_consent .cookie-notice .cn-body p.cn-ok .cm-btn.cm-btn-success,
a.button,
.button,
.btn{
	appearance: none;
	background-color: transparent;
	border: 1px solid $base-font-color;
	//border-radius: $base-border-radius;
	color: $base-font-color;
	cursor: pointer;
	font-family: $base-font-family-bold;
	@include font-size(1.5, 1.7);
	-webkit-font-smoothing: antialiased;
	padding: 0 $base-spacing/2;
	display: inline-flex;
	align-items: center;
	height: 40px;
	text-decoration: none;
	transition: all $base-duration $base-timing;
	user-select: none;
	vertical-align: middle;
	//white-space: nowrap;
	text-align: center;
	text-transform: uppercase;

	&:hover,
	&:focus {
		border: 1px solid $primary-color-2;
		background-color: $grey-3;
		outline: 0;
	}
	
	i{
		transition: all $base-duration $base-timing;
	}

	
	&--in-main-menu{
		text-transform: none;
		justify-content: center;
		border: 1px solid $white;
		color: $white;	
		display: flex;
		&:focus,
		&:active,
		&:hover{
			background-color: $white;
			color:$primary-color-2;
			border: 1px solid $white;
		}
		
	}
	
	&--award{
		background-color: $white;
		img{
			height: 85%;
		}
	}
	
	&--white{
		border: 1px solid $white;
		i{
			color:$white;
		}
		&:hover,
		&:focus {
			border: 1px solid $primary-color-2;
			background-color: $grey-3;
			i{
				color:$primary-color-2;
			}
			
		}
	}


	&--bdzvplus{
		background: $secondary-color-13;
		color: $primary-color-1;
		color:$white;

		&:hover,
		&:focus {
			//border: 1px solid $primary-color-2;
			//background-color: $grey-3;
			color: $base-font-color;
		}
	}
	
	&--blue{
		border: 1px solid $primary-color-1;
		background-color: $primary-color-1;
		color: $white;
		i{
			color:$white;
		}
		&:hover,
		&:focus {
			color:$base-font-color;
			i{
				color:$base-font-color;

			}
			
		}
		
	}
	
	
	&--submit{
		border: 1px solid $primary-color-1;
		color: $primary-color-1;
		i{
			color:$primary-color-1;
		}
		&:hover,
		&:focus {
			color:$white;
			background-color: $primary-color-1;
			i{
				color:$white;

			}
			
		}
		
	}
	
	&--with-arrow{
		display: flex;
		align-items: center;
		justify-content: space-between;
		display: inline-flex;
		i{
			@include font-size(2.7);
			margin-left: $base-margin/2;
		}
	}
	
	&--all-topics{
		border:0;
		border-bottom:2px solid $white;
		justify-content:flex-end !important;	
		text-transform: uppercase;
		&:focus,
		&:active,
		&:hover{
			border:0;
			border-bottom:2px solid $white;
			
			i{
				margin-left: $base-margin*1;
			}
			
		}
	}
	
	

	
	&--cta{
		height: 100px;
		border: 0px;
		@include font-size(2.4,6.5);
		padding: 0;
		font-family: $heading-font-family-style-2;
		text-transform: none;
		padding-left: $base-padding*2;
		padding-right: $base-padding*2;
		padding-bottom: 6px;
		justify-content: center;
		align-items: center;
		background-color:$grey-1;
		&:hover{
			border: 0;
		}
		i{
			margin-top: 5px;
			&::before{
				font-weight: 300;
			}
		}
	}
	
	&--button-white{
		border-color: $white;
		color:$white;
		&:focus,
		&:hover{
			background-color: $primary-color-1;
			color:$white;
		}
	}
	
	
	
	
	&--with-icon{
		height: 50px;
	}
	
	&--with-icon-left{
		i{
			position: relative;
			@include font-size(2,0);
			padding-right:$base-padding/2;
			margin-right: $base-margin/2;
			
			&::after{
				content: '';
				width:1px;
				height:30px;
				background-color: $base-font-color;
				position: absolute;
				right:0;
				top:-15px;
			}
			
		}
	}
	
	&--with-icon-right{
		justify-content: flex-end;
		i{
			position: relative;
			@include font-size(2,0);
			padding-left:$base-padding/2;
			margin-left: $base-margin/2;
			
			&:after{
				content: '';
				width:1px;
				height:30px;
				background-color: $base-font-color;
				position: absolute;
				left:0;
				top:-15px;
			}	
		}
		
		&.button--blue{
			i:after{
				background-color:$white;
			}
			&:hover,
			&:focus{
				i:after{
					background-color:$base-font-color;
				}
			}
		}
		&.button--submit{
			i:after{
				background-color:$primary-color-1;
			}
			&:hover,
			&:focus{
				i:after{
					background-color:$white;
				}
			}
		}
	}
	
	&--width-full{
		 flex-basis: 100%;
	}
	
	&--width-100{
		width: 100px;	
	}
	&--width-200{
		width: 200px;	
	}
	&--width-300{
		width: 300px;	
	}
	
	&--round{
		border-width:1px;
		width: 50px;
		min-width: 50px;
		height: 50px;
		padding: 0;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		border-color:$white;
		i{
			@include font-size(2.7);
			margin-left: 1px;
		}
		&:hover,
		&:focus {
			border: 1px solid $white;
			background-color: $grey-3;
		}
	}
	
	&--round--no-border{
		border-width:0;

		padding: 0;
		justify-content: center;
		align-items: center;
		i{
			@include font-size(2.7);
			margin-left: 1px;
		}
		&:hover,
		&:focus {
			border: 0;
			background-color: $white;
			color: $base-action-color;
		}
	}
	
	&--formbutton{
		background-color: $white;
		width: 100%;
		height: 50px;
		text-align: left;
		cursor: text;
		user-select:auto;
		&:hover,
		&:focus {
			background-color: $white;
			color: $base-font-color;
		}
		
	}
	
	&--formbutton-login{
		background-color: $white;
		width:100%;
		max-width: 300px;
		height: 50px;
		text-align: left;
		cursor: text;
		user-select:auto;
		text-transform: none;
		&:hover,
		&:focus {
			background-color: $white;
			color: $base-font-color;
		}
	}
	
	&--formbutton-search{
		text-align: left;
		position: relative;
		padding-left: $base-padding*2.5;
		
		background: $white url('../Images/Icons/ic_search.svg') $base-padding/1.5 center no-repeat;
		background-size: $base-padding*1.5;
	}
	

	&--tag{
		border-radius: 25px;
		padding: 0 $base-padding*1.2;
	}
	
	&--filter{
		border-radius: 25px;
		padding: 0 $base-padding;
		padding-right: $base-padding/2;
		display: flex;
		align-items: center;
		justify-content: space-between;

		i{
			@include font-size(2.7);
			margin-left: $base-margin/2;
			color:$base-link-color;
		}
		
		&:active,
		&:hover{
			i{
				color: $base-action-color;
			}
		}
	}
	
	&--filter--all{
		border-radius: 25px;
		padding: 0 $base-padding;
		padding-right: $base-padding/2;
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: $white;
		background-color: $primary-color-1;

		i{
			@include font-size(2.7);
			margin-left: $base-margin/2;
			color:$white;
		}
		
		&:active,
		&:hover{
			background-color: $grey-3;
			color: $base-font-color;
			i{
				color: $base-action-color;
			}
		}
	}
	
	&--subthemen{
		border-radius: 25px;
		padding: 0 $base-padding;
		padding-left: $base-padding/2;
		display: flex;
		align-items: center;
		justify-content: space-between;

		i{
			@include font-size(2.7);
			margin-right: $base-margin/2;
			color:$base-link-color;
			@include set-item-color(color);
		}
		
		&:active,
		&:hover{
			i{
				color: $base-action-color;
			}
		}
	}
}




.klaro.we_cookie_consent .cookie-notice,
.klaro.we_cookie_consent .cookie-notice .cn-body{
	background-color: $grey-3;	
}

.klaro.we_cookie_consent .cookie-notice .cn-body p.cn-ok .cm-btn.cm-btn-success{
	justify-content: center;
	background-color: $primary-color-4;
	color: $white;
	&:hover,
	&:focus{
		color: 	$primary-color-1;
	}
}


.menu-toggle-button{
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding:0;
	border: 0;
	background: none;
	font-family: $base-font-family;
	outline: 0;
	margin:0;
	
	&:focus,
	&:active{
		outline: 0;
	}
	
	&::after{
		content: '\f00d';
		font-family: $fontawesome;
		@include font-size(2);
		transform: rotate(-90deg);	
		transition: all $base-duration $base-timing;
	}
	
	&.collapsed{
		&::after{
			
			content: '\f107';
			transform: rotate(0);	
		}
	}
}

.dropdown-menu{
	border: 1px solid $base-font-color;
	border-radius: 0;
	//min-width: 175px;
	
	
	&__content{
		display: flex;
		flex-direction: column;
		@include font-size(1.6);
		padding: $base-padding/2 $base-padding;
		
		a{
			color: $base-font-color;
			white-space: nowrap;
			&:hover{
				color: $base-action-color;
			}
		}
		
	}
}
