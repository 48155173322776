.module-article-form {
	@include make-row();
	margin-bottom: $base-margin*3;

	
	&__inner {
		
//		background-color: red;

		form{
			background-color: $grey-3;
			padding: $base-padding*1.5;
		}
		
		fieldset{
			border: 1px solid $white;
			padding: $base-padding;
			
			legend{
				padding: 0 $base-padding;
				font-family:$base-font-family-bold;
			}
		}
		
		label{
			@include font-size(1.5);
			
		}
		
		input[type=text],
		input[type=email],
		input[type=email],
		input[type=number],
		input[type=url],
		input[type=tel],
		textarea,
		select{
			border: 1px solid $grey-1;
			@include font-size(1.6);
			outline: 0;
		}
		
		

		
		input[type=number]{
			width:100px;
		}
		
		input[type=tel]{
			width:150px;
		}
		
		
		select{
			padding-right: $base-padding;
		}
		
		textarea{
			min-height:123px;
		}
		
		input[type='radio'],
		input[type='checkbox']{
			margin:0;
			padding:0;
			margin-left: $base-margin/2;
			margin-right: $base-margin/2;
		}
		
		span.error{
			color:red;
			@include font-size(1.5);
		}
		
		.form-inline{
			.form-group{
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				margin-bottom: $base-margin/2;
				
				label{
					margin-bottom: 0.5rem;
				}
				
			}
		}
		
		.checkbox .form-check,
		.radio{
		//	padding: 0;
			
			
			&>label{
				display: flex;
				align-items: center;
				flex:1;
		
				.form-check-input{
					position: relative;
				}
				
				input[type='checkbox']{
					align-self: flex-start;
					border: 1px solid red;
					margin-top: 4px;
					display: inline-block;
					width: 20px;
					margin-right: $base-margin/3;
				}

				&>span{
					margin: 0;
					display: block;
				}
			}
		}
		
		.checkbox .form-check{
			padding: 0;
		}
		
		button{
			margin-top:$base-margin;
		}
		
		@include setBasicModuleWidth-Slim();
		
		
			.content-wrapper-two-columns & {
				@include setBasicModuleWidth-Full();
			}

			
		@include media-breakpoint-down(lg) {
		
		}
		
		@include media-breakpoint-down(md) {
		
		}
		
		@include media-breakpoint-down(sm) {
		
		}	
	}
}


