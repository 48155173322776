.module-article-stage-standard {
	@include make-row();
	margin-bottom: $base-margin*2;
	
	&__inner {
		@include setBasicModuleWidth-Full();
		
		padding-left: 0;
		padding-right: 0;

			
		@include media-breakpoint-down(lg) {
		
		}
		
		@include media-breakpoint-down(md) {
		
		}
		
		@include media-breakpoint-down(sm) {
		
		}	
	}
}