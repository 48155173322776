
.module-article-terminkalender {
	@include make-row();
	margin-bottom: $base-margin*3;
	
	&__inner {
		@include setBasicModuleWidth-Medium();

			
		@include media-breakpoint-down(lg) {
		
		}
		
		@include media-breakpoint-down(md) {
		
		}
		
		@include media-breakpoint-down(sm) {
		
		}	
	}
}

.calendar-list{

	@include reset-list();

	&__item{
		
		margin-bottom: $base-margin;
		
		&__content-wrapper{
			display: flex;
			flex-direction: column;
			//border-left:4px solid $primary-color-4;
			@include set-topic-border(border-left,4px);
			
			&--bdzvplus{
				border-left:4px solid $secondary-color-13 !important;
			}
			
			
			&__extended,
			&__basic{
				display: flex;
				justify-content: space-between;
				border-bottom: 1px solid $grey-2;
				padding: $base-padding;
				
				
				
				p{
					margin-bottom: 0;
				}
				
				
				
				&__options{
					text-align: right;
					@include font-size(1.5);
					p{
						margin-top: $base-margin/4;
					//	align-self: flex-end;
					}
					a{
						color: $grey-1;
						
						&::after{
							color:$base-link-color;
						}
						
						&:hover{
							text-decoration: underline;
						}
					}
					display: flex;
					flex-direction: column;
					justify-content: flex-end;
				}
				
				@include media-breakpoint-down(md) {
					flex-direction: column;
				
					&__options{
						text-align: left;
						margin-top: $base-margin;
					}
				
				}
		
				@include media-breakpoint-down(sm) {
		
				}
				
			}
			&__extended{
				border-bottom: 0;
				background-color: $grey-4;
				
				p{
					@include font-size(1.5);
					max-width: 400px;
					margin-bottom: $base-margin/2;
				}
				div.image-wrapper{
					display: block;
					width: 200px;
					
					margin-right:-$base-margin;
					margin-top:-$base-margin;
					
					.figure{
						margin-bottom: $base-margin/2;
						border: 4px solid $grey-2;
					}
					
					
					@include media-breakpoint-down(md) {
						margin: 0;
					}
					
				}
			}
		}
		
		&__with-date-wrapper{
			//display: flex;
			
			&__date{
				@include make-col(1.5);
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				
				@include media-breakpoint-down(lg) {
					@include make-col(2);
				}
				
				@include media-breakpoint-down(md) {
				
				}
				
				@include media-breakpoint-down(sm) {
					@include make-col(3);
				}
				
				p{
					margin-bottom: 0;
				}
				.weekday{
					@include font-size(1.3);
				}
				.day{
					@include font-size(2.8);
					font-family: $base-font-family-light;
				}
			}
			.calendar-list__item__content-wrapper{
				@include make-col(10.5);
				@include media-breakpoint-down(lg) {
					@include make-col(10);
				}
				
				@include media-breakpoint-down(md) {
				
				}
				
				@include media-breakpoint-down(sm) {
					@include make-col(9);
				}
			}
		}
		
		
		.calendar-item-title{
			@include font-size(2.1);
		}
		
		.calendar-item-time{
			@include font-size(1.6);
			margin-bottom: $base-margin/2;
		}
		
		.calendar-item-place{
			@include font-size(1.5);
			color:$grey-1;
		}
		
		.calendar-item-description{
			
		}	
	}	
}

.calendar-menu-wrapper{
	

	&__inner{
		
		border-bottom: 2px solid $grey-3;
		margin-bottom: $base-margin;
		padding-bottom: $base-padding;
		
		.row{

			align-items: center;
		}

		&__year{
			@include make-col(1.5);
			@include media-breakpoint-down(lg) {
				@include make-col(2);
			}
			
			@include media-breakpoint-down(md) {
			
			}
			
			@include media-breakpoint-down(sm) {
				@include make-col(12);
				margin-bottom: $base-margin;
			}
			
			nav{
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 0 $base-padding/2;
				@include media-breakpoint-down(sm) {
					justify-content: flex-start;	
				}
				
				span{
					i{
						&::before{
							font-weight: 900;
						}
					}
				}	
				a{
					color: $base-font-color;
					&:hover{
						color: $base-action-color;	
					}
				}
				.year{
					@include font-size(2.8);
					font-family: $base-font-family-light;
					margin: 0 $base-margin/2;
				}
			}

			
		}
		&__month{
			@include make-col(3.5);
			@include media-breakpoint-down(lg) {
				@include make-col(3.5);
			}
			
			@include media-breakpoint-down(md) {
				@include make-col(3);
			}
			
			@include media-breakpoint-down(sm) {
				@include make-col(12);
				margin-bottom: $base-margin;
			}
			nav{
				padding: 0 $base-padding/2;
			}
		}
		&__type{
			text-align: right;
			@include make-col(7);
			@include media-breakpoint-down(lg) {
				@include make-col(6.5);
			}
			@include media-breakpoint-down(md) {
				@include make-col(6.5);
			}
			@include media-breakpoint-down(sm) {
				@include make-col(12);
				text-align: left;
			}	
			
			p{
				margin-bottom: 0;
				margin-right: $base-margin/2;
				.button{
					height: 50px;
				}
				@include media-breakpoint-down(sm) {
					margin-top: $base-margin;	
				}
			}

			
			nav{
				display: flex;
				padding: 0 $base-padding/2;
				align-items: center;
				justify-content: flex-end;
				@include media-breakpoint-down(sm) {
					justify-content: flex-start;	
					flex-direction: column;
					align-items: flex-start;
					flex-direction: column-reverse;
				}
			}
		}
	}
}
