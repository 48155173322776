.module-article-zitat-position {
	@include make-row();
	margin-bottom: $base-margin*3;
	margin-top:$base-margin*2;
	
	&__inner {
		@include setBasicModuleWidth-Full();
		
		
		.zitat-position-description{
			@include make-row();
			&__inner{
				@include setBasicModuleWidth-Medium();				
			}
		}
		
		.zitat-position-content{
			@include make-row();
			&__inner{
				@include setBasicModuleWidth-Full();				
			}
		}
		

		.zitat-position-container {
			@include make-row();
			&__inner {
				@include setBasicModuleWidth-Slim();
				z-index:20;
				
				.row{
					
					.content-left{
						@include make-col-ready();
						@include make-col(5);
						@include media-breakpoint-down(sm) {
								@include make-col(10);
								@include make-col-offset(1);
						}
					}
					
					.content-right{

						@include make-col-ready();
						@include make-col(6);
						@include make-col-offset(1);
						@include media-breakpoint-down(sm) {
								@include make-col(10);
								@include make-col-offset(1);
								margin-top: $base-margin;
						}

						display: flex;
						flex-direction: column;
						justify-content: center;
						div{
							p:last-child(){
								margin-bottom: 0;
							}
						}
						
						&>p{
							position: absolute;
							bottom: $base-margin;
							margin-bottom: 0;
							left:calc(8.33333333% + 10px);
							@include media-breakpoint-down(sm) {
								position: relative;
								left: 0;	
								bottom: 0;
							}
						}
						
						.module-article-blockquote{
							margin-bottom: 0;
						}
						
						.module-article-blockquote__inner__wrapper{
							@include font-size(2);
						}
						
					}
				}
				
				
				
				.content-wrapper-two-columns & {
					@include setBasicModuleWidth-Medium();
				}
				
			}
		}
		
		margin-bottom:-320px;
		.behind-text{
			display: block;
			background-color: $grey-3;
			position: relative;
			height: 320px;
			margin-left: -$base-margin/2;
			margin-right:-$base-margin/2;
			top: -50%;
			z-index: 10;
		}
		
		.content-wrapper-two-columns & {
			margin-bottom:-200px;
			.behind-text{
				height: 200px;
			}
		}
		
		
		@include media-breakpoint-down(lg) {
			margin-bottom:-220px;
			.behind-text{
				height: 220px;
			}
			.content-wrapper-two-columns & {
				margin-bottom:-130px;
				.behind-text{
					height: 130px;
				}
			}
		}
		
		@include media-breakpoint-down(md) {
			margin-bottom:-220px;
			.behind-text{
				height: 220px;
			}
			.content-wrapper-two-columns & {
				margin-bottom:-250px;
				.behind-text{
					height: 250px;
				}
			}
		}
		
		@include media-breakpoint-down(sm) {
			margin-bottom:-780px;
			.behind-text{
				height: 780px;
				//display: none;
			}
			.content-wrapper-two-columns & {
				margin-bottom:-150px;
				.behind-text{
					height: 150px;
				}
			}
		}	
	}
}