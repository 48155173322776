.module-infobox{
	
	@include make-row();
	margin-bottom: $base-margin;
	
	&__inner{
		
		@include setBasicModuleWidth-Slim();
		
		&__design{
			border: 1px solid $grey-2;
			border-top:4px solid $primary-color-1;
			@include set-topic-border(border-top);
			padding: $base-padding;
			position: relative;
		
			.infobox-icon{
				position: absolute;
				right: $base-margin*1.5;
				top:$base-margin/2;
				@include font-size(6);
				color: $primary-color-1;
				@include set-topic-color(color);
			}
		
		}
		
		
		.module-article-tabs & ,
		.content-wrapper-two-columns & {
			@include setBasicModuleWidth-Full();
		}
		
		
		
		
		h2,h3,h4{
			//margin-bottom: 0;
		}
		
	}
	
}