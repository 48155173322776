//reset Bootsrap default styles

.nav-pills {
	
	.nav-link{
		//padding: 0;
		border: 0;
		border-radius: 0;
		
		&.active{
			background-color:transparent;
			color: $base-font-color;
		}
	}
}


.article-filter{
	
	margin: $base-margin 0;
	
	&__item{
		margin-right: 2rem;
		@include font-size(1.8,1.8);
		padding: 0.3rem 0 !important;
		
		&.active{
			border-bottom: 3px solid $secondary-color-1;
		}
	}
	
}