.stage-function{
	@include make-row();
	background-color: $grey-3;
	padding-top:$base-padding*4;
	padding-bottom: $base-padding*1.5;
	@include set-topic-color(background-color);
	min-height: 320px;
	margin-bottom: $base-margin*2;
	
	&__inner {
		@include setBasicModuleWidth-Full();
		
		&__content-area{
			@include make-row();
			&__inner{
				@include setBasicModuleWidth-Medium();
				
				header{
					display: flex;
					justify-content: space-between;
					align-items: center;
					border-bottom: 2px solid $base-font-color;
					padding-bottom: $base-padding*2;
					margin-bottom: $base-margin*2;
				
					h2,h3,h4{
						font-family: $heading-font-family-style-2;
						margin-bottom: 0;
					}
					
					.icon{
						max-height: 60px;	
						max-width: 60px;
						margin-right: $base-margin*1.5;	
						display: flex;
						align-items: center;
						justify-content: center;
						
						img{
							max-width: 60px;
							max-height: 60px;
						}
					}
				}	
				p{
					@include font-size(1.6);
					width:80%;	
				}
			}
		}
		
		.topic--primary-color-1 &,
		.topic--primary-color-2 &,
		.topic--primary-color-3 &,
		.topic--primary-color-4 &,
		.topic--secondary-color-1 &,
		//.topic--secondary-color-2 &,
		.topic--secondary-color-3 &,
		.topic--secondary-color-4 &,
		//.topic--secondary-color-5 &,
		.topic--secondary-color-6 &,
		.topic--secondary-color-7 &,
		.topic--secondary-color-8 &,
		.topic--secondary-color-9 &,
		.topic--secondary-color-13 & {
			
			color:$white;	
			
			header{
				border-color: $white;
			}
		}

			
		@include media-breakpoint-down(lg) {
		
		}
		
		@include media-breakpoint-down(md) {
			
			&__content-area{
			
				&__inner{
					
					padding-left: $base-padding*2;
					padding-right: $base-padding*2;
					
					p{
						width: 100%;
					}
				}
			}
		}
		
		@include media-breakpoint-down(sm) {
		
			&__content-area{
			
				&__inner{
					
					padding-left: $base-padding;
					padding-right: $base-padding;
					
					header{
						
						padding-bottom: $base-padding;
						margin-bottom: $base-margin;
						
						h2,h3,h4{
							@include font-size($h3-font-size,0);
						}
					
						.icon{
						//	margin-right: 0;
						}
					}
					
				}
			}
		
		}	
	}
	
	&--searchbox{
	
		
					align-items: center;
					padding-top: 0;
					padding-bottom: 0;
	
			&__inner {
				@include setBasicModuleWidth-Full();
				
				&__content-area{
					@include make-row();
					display: flex;
					align-items: center;
					&__inner{
					@include setBasicModuleWidth-Medium();

					
					
					form{
						
						border-bottom: 2px solid $base-font-color;
						display: flex;
						justify-content: space-between;
						align-items: center;
						padding-bottom: $base-padding*2;
					
						input{
							width: 90%;
							height:50px;
							padding: 0 $base-padding;
							background-color: transparent;
							border: 0;
							border-left: 3px solid $grey-2;
							font-family: $heading-font-family-style-2;
							@include font-size(3);
							//outline: 0;
							&::placeholder{
								color: $grey-2;
							}
						}
						
						button{
							
							border:0;
							background-color: transparent;
							color: $base-link-color;
							
							img{
								width:40px;
								height:40x;
								color: $base-link-color;
							}
						}
						
					}
					
				
					.topic--primary-color-1 &,
					.topic--primary-color-2 &,
					.topic--primary-color-3 &,
					.topic--primary-color-4 &,
					.topic--secondary-color-1 &,
					//.topic--secondary-color-2 &,
					.topic--secondary-color-3 &,
					.topic--secondary-color-4 &,
					//.topic--secondary-color-5 &,
					.topic--secondary-color-6 &,
					.topic--secondary-color-7 &,
					.topic--secondary-color-8 &,
					.topic--secondary-color-9 & {
						
						color:$white;	
						
						form{
							border-color: $white;
							input{
								color: $white;
								}
						}
					}	
					
				}
			}
		}
	}
}


.stage-teaser {
	
	position: relative;
	
	
	
	&__eventintro{
		
		position: absolute;
		top:$base-margin;
		height: auto;
		width:45%;
		display: flex;
		flex-direction: column;
		transition: all $base-duration $base-timing;
		
		@include media-breakpoint-down(lg) {
			width:62%;
		}
		
		@include media-breakpoint-down(md) {
			width:90%;
			height: auto;
			left:5%;
			top:-$base-margin*3;
			position: relative;
		}
		
		
		&__wrapper{

			display: flex;
			height: auto;
			max-height: 100%;
			flex-direction: column;
			justify-content: space-between;
			color: $base-font-color;
			background-color: $grey-4;
			transition: all $base-duration $base-timing;
			
			
			&__top{
				background-color: $grey-3;
				padding: $base-padding;	
				color: $base-font-color;
				transition: all $base-duration $base-timing;
				
				span{
					margin-bottom: $base-margin/2;
					display: block;
				}
				
				
				h2,h3,h4,h5{
					font-family: $heading-font-family-style-2;
					
					@include font-size($h3-font-size,4);
						
					
					@include media-breakpoint-down(sm) {
						@include font-size($h6-font-size);	
					}
					
					.content-wrapper-two-columns & {
						
						@include font-size($h3-font-size,4);
				
					
						@include media-breakpoint-down(sm) {
							@include font-size($h6-font-size);	
						}
					
					}
				}
				&>div{
					display: flex;
					justify-content: space-between;
					align-items: center;
				}
				
				&:hover{
				
				background-color: $grey-3;
				
				p.button{
					margin-right:-$base-margin/2;
				}
			}
				

			}
			
			&__bottom{
				background-color: $secondary-color-13;
				color: $white;
				padding: $base-padding;	
				display: flex;
				align-items: center;
				justify-content: space-between;
				
				
				
				//color: $white;
				a{
					color: $white;
					border-color: $white;
					
					&:hover,
					&:focus{
						color: $secondary-color-13;
						border-color: $secondary-color-13;
					}
					
				}
				
				p{
					margin-bottom: 0;
				}
				@include media-breakpoint-down(sm) {
					flex-direction: column;	
					align-items: flex-start;
					
					p:nth-child(1){
						margin-bottom: $base-margin;
					}
				}
			}
			
			
				
		}
	}
	
	
	
	&__text{

		position: absolute;
		top:12%;
		height: 76%;
		width:47%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		
		transition: all $base-duration $base-timing;
		
		@include media-breakpoint-down(lg) {
			width:70%;
			height: 82%;
			top:9%;
		}
		
		@include media-breakpoint-down(md) {
			width:90%;
			height: auto;
			left:5%;
			top:-$base-margin*3;
			position: relative;
			
		}
		
		
		&--rechts{
			right:0;
		}
		
		&>a{
			padding: $base-padding	 $base-padding*1.5;	
			display: flex;
			height: auto;
			max-height: 100%;
			flex-direction: column;
			justify-content: center;
			color: $base-font-color;
			background-color: $grey-2;
			transition: all $base-duration $base-timing;
			
			&:hover{
				
				background-color: $grey-3;
				
				p.button{
					margin-right:-$base-margin/2;
				}
			}
			
			h2,h3,h4{
				display: flex;
				flex-direction: column;
				align-self: flex-start;
				&::after{
					content: '';
					height:4px;
					width:90px;
					background-color: $base-font-color;
					margin-top: $base-margin;
					align-self: flex-start;
				}
			}
			
			p.teaser-description{
				@include font-size(1.6);
				margin-top: $base-margin;
				margin-bottom: $base-margin;
			}
			
			p.button{
				align-self: flex-end;
				margin-bottom: 0;
			}
			
			header{


			display: flex;
		//	align-items: flex-start;
			
			}
		}
	}

	
	h2:not(.event-title),h3,h4,h5{
		font-family: $heading-font-family-style-2;
		
		@include font-size($h1-font-size,5.4);
			
		@include media-breakpoint-down(lg) {
			@include font-size($h2-font-size,5.0);
			
		}
		
		@include media-breakpoint-down(md) {
			@include font-size($h3-font-size,4);
		}
		
		@include media-breakpoint-down(sm) {
			@include font-size($h6-font-size);	
		}
		
		.content-wrapper-two-columns & {
			
			@include font-size($h3-font-size,4);
		
			@include media-breakpoint-down(lg) {
				@include font-size($h5-font-size);	
			}
			
			@include media-breakpoint-down(md) {
				@include font-size($h3-font-size,4);	
			}
		
			@include media-breakpoint-down(sm) {
				@include font-size($h6-font-size);	
			}
		
		}
		
		margin: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

		
	&__content-wrapper{
		
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		color: $white;

		&--show-black-layer{
			&::before{
				background-color: $black;
				opacity: 0.5;
				display: block;
				height: 100%;
				width:100%;
				content: '';
				position: absolute;
			}
		}
				
		
		&__content{
		
			position: absolute;
			height: 100%;
			width: 100%;
			display: flex;
			flex-direction: column;
			z-index: 60;
			text-align: center;
		
			
			
			header{
				padding-left: 15%;
				padding-right: 15%;
				
				@include media-breakpoint-down(lg) {
					padding-left: 10%;
					padding-right: 10%;
				}
				
				@include media-breakpoint-down(md) {
					padding-left: 5%;
					padding-right: 5%;
				}
				
				@include media-breakpoint-down(sm) {
					padding-left: $base-padding;
					padding-right: $base-padding;
				}
				
				.content-wrapper-two-columns & {
					@include media-breakpoint-down(xl) {
						padding-left: 0;
						padding-right: 0;
					}	
				}
				
				
			}
			
			/*
			
			&--startseite{
				justify-content: center;
				align-items: flex-start;
				text-align: left;
				
				&--rechts{
					align-items:flex-end;
				}
				
				@include media-breakpoint-down(md) {
					align-items: center;	
					justify-content: flex-start;
					
				}
				
				
				&__top{	
					width:47%;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					transition: all $base-duration $base-timing;
					
					@include media-breakpoint-down(lg) {
						width:55%;
					}
					
					@include media-breakpoint-down(md) {
						width:90%;
						margin-top: 250px;
					}
					
					@include media-breakpoint-down(sm) {
						margin-top: 200px;
					}
					
				
					
					&>a{
						padding: $base-padding	 $base-padding*1.5;	
						display: flex;
						flex-direction: column;
						color: $base-font-color;
						background-color: $grey-2;
						transition: all $base-duration $base-timing;

						
						&:hover{
							
							background-color: $grey-3;
							
							p.button{
								margin-right:-$base-margin/2;
							}
						}
						
					}
					
				
										
					
					
					
					p.teaser-description{
						@include font-size(1.6);
						margin-top: $base-margin;
						margin-bottom: $base-margin;
					}
					
					p.button{
						align-self: flex-end;
						margin-bottom: 0;
					}
				}
			}
			*/

			
			&--standard{
				&__top{
					height: 100%;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					transition: all $base-duration $base-timing;
				}

				p.overline{
					@include font-size(1.3);
					text-transform: uppercase;
					margin-bottom: $base-margin/2;
				}
				
				p.teaser-description{
					padding-left: 20%;
					padding-right: 20%;
					margin-top: $base-margin;
					margin-bottom: 0;
					
					@include media-breakpoint-down(md) {
						padding-left: 10%;
						padding-right: 10%;
						@include font-size(1.5);
					}
					@include media-breakpoint-down(sm) {
						&:not(.overline){
							display:none;
						}				
					}
				}
				
				.case-logo{
					width: 100px;
					height: 100px;
					border-radius: 50%;
					background-color: $white;
					margin-top: $base-margin;
					border: 2px solid $grey-1;
					
					@include media-breakpoint-down(lg) {
						width: 70px;
						height: 70px;
					}
				
					@include media-breakpoint-down(md) {
						display:none;				
					}
				}
				
				&::after,
				&::before{
					content: '';
					width: 2px;
					height: 100px;
					background-color: $white;
					position: absolute;
					left: 50%;
					top:0;
					margin-left: -1px;
					transition: all $base-duration*2 $base-timing;
					
					@include media-breakpoint-down(lg) {
						height: 50px;
					}
				}
				
				&::after{
					bottom:0;
					top:auto;
				}
			}
			
			&--galerie{
				&__top,
				&__bottom{
					flex:1;
					height: 50%;
					display: flex;
					justify-content: center;
					align-items: center;
				}		
				
				header{
					padding-left: 10%;
					padding-right: 10%;

					
					@include media-breakpoint-down(lg) {
						padding-left: 5%;
						padding-right: 5%;
					}
					
					@include media-breakpoint-down(sm) {
						padding-left: $base-padding/2;
						padding-right: $base-padding/2;
					}
				}
				
				h2,h3,h4,h5{
					&::after{
						content: '';
						height:4px;
						width:90px;
						background-color: $white;
						margin-top: $base-margin*2;
					}
					@include media-breakpoint-down(sm) {
						&::after{
							width:50px;
							margin-top: $base-margin;
						}
					}			
					
					.content-wrapper-two-columns & {
					
						@include media-breakpoint-down(lg) {
							&::after{
								width:50px;
								margin-top: $base-margin;
							}
						}
					}	
				}
				
				p{
					margin-bottom: 0;
				}			
			}	
			
		}
	}
		
	&__placeholder {
		
		background-color: $grey-1;
		
				
		&--2zu1,
		&--3zu1,
		&--1zu1,
		&--16zu9,
		&--4zu3,
		&--3zu4 {
			position: relative;
			& > :first-child {
			    position: absolute;
			    top: 0;
			    left: 0;
			    height: 100%;
			}
		}
		&--16zu9 {
			&::before {
				content: "";
				display: block;
				padding-bottom: calc(100% / (16/9));
	  		}
		}
		&--4zu3 {
			&::before {
				content: "";
				display: block;
				padding-bottom: calc(100% / (4/3));
	  		}
		}
		&--3zu4 {
			&::before {
				content: "";
				display: block;
				padding-bottom: calc(100% / (3/4));
	  		}
		}
		&--3zu1 {
			&::before {
				content: "";
				display: block;
				padding-bottom: calc(100% / (3/1));
	  		}
	  		
	  		@include media-breakpoint-down(sm) {
		  		&::before {
					content: "";
					display: block;
					padding-bottom: calc(100% / (4/3));
		  		}	
		  	}
		}
		&--2zu1 {
			&::before {
				content: "";
				display: block;
				padding-bottom: calc(100% / (2/1));
	  		}
	  			  		
	  		@include media-breakpoint-down(sm) {
		  		&::before {
					content: "";
					display: block;
					padding-bottom: calc(100% / (4/3));
		  		}	
		  	}
	  		
		}
		&--1zu1 {
			&::before {
				content: "";
				display: block;
				padding-bottom: calc(100% / (1/1));
	  		}
		}		
	}
}