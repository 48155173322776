.module-login-box {
	@include make-row();
	//margin-top: $base-margin*2;
	margin-bottom: $base-margin*2;

	
		
	.form-group{
		&--trenner{
			margin-top: $base-margin*2;

			
		}
		
	}


	&__inner {
		@include setBasicModuleWidth-Medium();
		background-color: $grey-3;
		@include media-breakpoint-down(sm) {
			@include setBasicModuleWidth-Full();
			padding: $base-padding  $base-padding*2;
		}
		padding: $base-padding * 2;
	}

	&__content-wrapper{

		&__inner{
			@include make-row();
			
			label{
				width: 100%;
			}
			
			&--text{
				@include make-col-ready();
				@include make-col(12);


				a {
					color:$white;
				}
			}
			&--form{
				@include make-col-ready();
				@include make-col(12);

			}
		}
	}
}
