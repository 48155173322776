@keyframes anchor-underline {
  0%, 10% {
    left: 0;
    right: 100%;
  }
  40%, 60% {
    left: 0;
    right: 0;
  }
  90%, 100% {
    left: 100%;
    right: 0;
  }
}


@keyframes pop-in {
  0% {
    opacity: 0;
    transform: translateY(-4rem) scale(.8);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

/*
@keyframes popout {
	0% {transform: scale(0); opacity: .5}
	80% {transform: scale(1.1); opacity: 1}
	100% {transform: scale(1);}
}

@keyframes hovering { // currently unused
  30% {transform: rotate(3deg);}
  60% {transform: rotate(-3deg);}
  80% {transform: scale(1.1);}
  100% {transform: scale(1.15) rotate(0);}
}
*/

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform:translateY(0);
  }
  40% {
    transform:translateY(-30px);
  }
  60% {
    transform:translateY(-15px);
  }
}

@mixin pop-in($delay: .3, $duration: .6, $child-delay: .06) {
  animation: pop-in #{$duration + s} cubic-bezier(0, 0.9, 0.3, 1.2) forwards;
  animation-iteration-count: 1;
  @for $i from 1 through 6 {
    &:nth-child(#{$i}) {
      animation-delay: #{($delay + ($i - 1) * $child-delay) + s};
    }
  }
}
