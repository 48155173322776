.table{
	
	color: $base-font-color;
	
	&--style-alternating{
		tbody tr:nth-child(odd){
			background-color: $grey-3;
		}
		
	}

	&.tx_bdzvwarnings{
		tr{
			@include font-size(1.4);
		}	
	}
	
}

.table thead tr th{
	border-bottom: 2px solid $primary-color-1;
	border-top:0;
}

.table tr td,
.table tr th{
	border-bottom: 1px solid $primary-color-2;
}

table caption{
	@include font-size($caption-font-size);
	margin-top: $base-margin/2;
}
