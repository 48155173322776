.page-header {
	z-index: 101;
	background-color: $white;
	position: fixed;
	width: 100%;
	z-index: 300;
	margin: 0 auto;
	left: 0;
	right: 0;
	//border-bottom: 1px solid $base-font-color;
	transition: padding-left $base-duration $base-timing;
	padding-top: $base-padding*1.5;
	padding-bottom: $base-padding*1.5;

	&--is-visible {
		padding-left: 200px;
	}


	@include media-breakpoint-down(md) {
		position: relative;	
		height: auto;
	}


	&__wrapper {


			&__inner {
			@include make-container();
			
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			height: 7.5rem;
			max-height: 7.5rem;


			@include media-breakpoint-down(md) {
				flex-direction: column;
			}
			
			
			
			&__logo-wrapper{
				display: flex;
				flex-direction: row;
				align-items: center;
				
				@include media-breakpoint-down(md) {
					margin-bottom: $base-margin*2;	
				}
					
				&__logo {
					
					
					display: flex;
					
					img {
						transition: all $base-duration $base-timing;
						width: 150px;
						height: auto;
					}

				}
					
			}
			

			&__meta-wrapper {

				.meta-navi {
					@include reset-list();
					display: flex;
					flex-direction: row;
					align-items: center;
					
					@include media-breakpoint-down(sm) {
						flex-wrap: wrap;
						justify-content: space-around;
					}
					
					
					&__item {
						display: flex;
						align-items: center;
						padding-left: $base-padding*3;
						@include font-size(1.5);
						
						@include media-breakpoint-down(lg) {
							padding-left: $base-padding*1.5;
						}
						
						@include media-breakpoint-down(md) {
							padding-left: $base-padding/2;
							padding-right: $base-padding/2;
						}
						
						/*&:not(:first-child){
							&::before{
								content: '';
								height: 40px;
								width:1px;
								background-color: $grey-1;
								margin-right: $base-margin*1.5;
							}
						}
						*/		
					}
				}	
			}
		}
	}
}

