html, body {
//	scroll-behavior: smooth;
}

body {
	&.overflow-hidden {
		overflow: hidden;
	}
	&.no-scrollbar {
		overflow-x: hidden;
	}
}

/*
.page-wrapper{
	transition: margin-left $base-duration $base-timing;

	&--is-visible{
		margin-left:200px;
	}
}
*/

main {
	padding-top: $header-height;
	
	@include media-breakpoint-down(md) {
		padding-top: 40px;	
	}
	
	@include media-breakpoint-down(sm) {
		padding-top: 60px;	
	}
}


/* outer container with max-width*/
.module-wrapper {

	&[id] {
		// fixes jumplink bug with fixed header
		$jumplink-offset: 10px;

		&::before {
			content: '';
			display: block;
			z-index: -1;
			height: $jumplink-offset;
			width: 100%;
			margin-top: $jumplink-offset * -1;
		}
		
	}
	

	&__inner {
		@include make-container();
		@include media-breakpoint-up(xxl) {
			margin-left: auto;
			margin-right: auto;
		//	max-width: 1371px;
		}

		.module-wrapper__inner{
			padding: 0;
		}

	}

	&::after {
		@include clearfix();
	}

	main > &:first-child {
	//	margin-bottom: $base-padding;
	}

	/*
		remove later!!!
	*/

	&--placeholder{
		&__inner{
			background-color: $grey-4;
			min-height: 100px;
			margin-bottom: $base-margin;

		}
	}
	.grid-example {
		border-right: 1px solid #d2d2d2;
		span{
			border: 1px solid #d2d2d2;
			border-right:0;
		}
	}
}


.content-wrapper-two-columns__inner__right .module-wrapper {
	&[id] {
		&::before {
			display: none;
		}
	}
}

.content-wrapper-two-columns{
	@include make-row();
	&__inner{
		@include setBasicModuleWidth-Medium();

		&__left{
			@include make-col-ready();
			@include make-col(8);
		}

		&__right{
			@include make-col-ready();
			@include make-col(3.5);
			@include make-col-offset(0.5);
		}

		@include media-breakpoint-down(md) {
			&__left{
				@include make-col-ready();
				@include make-col(12);
			}

			&__right{
				@include make-col-ready();
				@include make-col(6);
				@include make-col-offset(0);
			}
		}

		@include media-breakpoint-down(sm) {
			&__left{
				@include make-col-ready();
				@include make-col(12);
			}

			&__right{
				@include make-col-ready();
				@include make-col(12);
				@include make-col-offset(0);
			}
		}
	}
}