.sammler-more-topics {
	@include make-row();
	background-color: $grey-4;
	padding-bottom: $base-padding*1;
	max-width: 450px;

	&__inner {
		@include setBasicModuleWidth-Full();
		padding: $base-padding;		
	}
}

.article-navigation{
	@include reset-list();
	display: flex;
	flex-direction: column;

	&__item{
			
		@include font-size(2.1);
		border-bottom: 1px solid $grey-1;
		
		&:hover{
			border-bottom: 1px solid $base-font-color;
		}	
	}
	
	&__link{
		position: relative;
		display: block;
		padding-left: 0;
		transition: all $base-duration $base-timing;
		padding: $base-margin/2 0;
		
		&::before{
			position: absolute;
			content: "\f061";
			font-family: $fontawesome;
			left:-$base-margin;
			transition: all $base-duration $base-timing;
			font-weight: 300;
			opacity: 0;
			@include set-topic-color(color);
		}
		
		&:active,
		&:hover{
			font-family: $base-font-family-bold;
			padding-left: $base-padding*1.5;
			&::before{
				left:0;	
				opacity: 1;
			}	
		}	
	}
}

.article--jump-navigation{
	@include reset-list();
	display: flex;
	flex-direction: column;

	&__item{
		//padding: $base-margin/2 0;	
		@include font-size(1.8);
		border-bottom: 1px solid $grey-1;	
	
		&:hover{
			border-bottom: 1px solid $base-font-color;
		}
	
	}
	
	&__link{
		position: relative;
		display: block;
		padding-left: 0;
		transition: all $base-duration $base-timing;
		padding: $base-margin/2 0;
		@include set-topic-color(color);
		
		
		
		&::before{
			position: absolute;
			content: "\f063";
			font-family: $fontawesome;
			top:-$base-margin;
			left:0;
			transition: all $base-duration $base-timing;
			font-weight: 300;
			opacity: 0;
			color: $base-font-color;
			
		}
		
		&:active,
		&:hover{
			font-family: $base-font-family-bold;
			padding-left: $base-padding*1.5;
			&::before{
				top:$base-margin/2;	
				left:0;
				opacity: 1;
			}	
		}	
	}
}



